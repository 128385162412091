import { client, Note } from "cadius-backend";
import { computePointNormalFromLoop, HalfEdgeMeshLast, SavedCamera } from "cadius-cadlib";
import { toPin } from "cadius-components";
import { log } from "cadius-stdlib";
import { ActionCreator } from "redux";
import { Color, Object3D, Vector3 } from "three";

import * as act from "../actions";
import { NEW_NOTE_INTERACTOR, NewNoteInteractor } from "../interactors/new_note";
import {
  CadiusDispatch,
  CadiusThunkAction,
  CategoryColor,
  IAction,
  IApplicationState,
  NoteIssue,
} from "../interfaces";
import { newLocalId } from "../utils";
import { appError } from "./errors";
import { hideDialog, showDialog } from "./ui";

// Close the popover with the note details.
export const dismissCurrentNote = (): CadiusThunkAction<void> => {
  return async (dispatch: CadiusDispatch): Promise<void> => {
    dispatch({ payload: { kind: NEW_NOTE_INTERACTOR }, type: act.POP_FROM_INTERACTOR_STACK });
    dispatch({ type: act.DISMISS_CURRENT_NOTE });
  };
};

/**
 * Start the interactor that allows the user to select a point on the model for
 * which he wants to create a new note.
 */
export const selectNewNotePosition: ActionCreator<IAction> = () => {
  const interactor = new NewNoteInteractor();
  return { payload: { interactor }, type: act.PUSH_TO_INTERACTOR_STACK };
};

// Mark the given note as selected.
export function selectNote(id: string): CadiusThunkAction<void> {
  return async (
    dispatch: CadiusDispatch,
    getState: () => IApplicationState
  ): Promise<void> => {
    const { notes } = getState();
    dispatch({ payload: { id, notes: notes.notes }, type: act.SELECT_NOTE });
  };
}

// TODO: this will be available when we have authentication.
const getUserCurrentlyLoggedIn = () => {
  const user = document.location.search.indexOf("user=s01") === 1 ? "s01" : "u01";
  return user;
};

// -------------------------------------------------------------------------- //

const getColor = (issue: NoteIssue) => {
  return issue === "geometry" ? CategoryColor.geometry : CategoryColor.material;
};

interface NoteOnFrontend {
  camera: SavedCamera;
  id: string;
  issue: NoteIssue;
  message: string;
  position: Vector3;
}

/**
 * Immediately add a note in the application state and in the scene.
 */
function addNote(noteOnFrontend: NoteOnFrontend): CadiusThunkAction<void> {
  const { camera, id, issue, message, position } = noteOnFrontend;
  const color = getColor(issue);

  return async (
    dispatch: CadiusDispatch,
    getState: () => IApplicationState
  ): Promise<void> => {
    const { project } = getState();

    const mesh = project.fundamentalEntities.last.geometry() as HalfEdgeMeshLast;
    const normalOnMeshByPoint = makeNormalOnMeshByPoint(mesh);
    const normal = normalOnMeshByPoint(position);
    const object3D = toPin({ color: new Color(color), normal, position });
    object3D.name = id;

    dispatch({ payload: { kind: NEW_NOTE_INTERACTOR }, type: act.POP_FROM_INTERACTOR_STACK });
    dispatch({
      payload: {
        camera,
        id,
        issue,
        message,
        position,
        username: getUserCurrentlyLoggedIn(),
      }, type: act.ADD_NOTE,
    });
    dispatch({ payload: { object3D }, type: act.ADD_OBJECT_3D });
  };
}

/**
 * Signal the app that the network request was successful, and update the note
 * with the id coming from the API.
 */
const replaceNoteId: ActionCreator<IAction> = (localId: string, backendId: string) => {
  return { payload: { backendId, localId }, type: act.REPLACE_NOTE_ID };
};

/**
 * Add a new note.
 *
 * The note is immediately added to the application state, so that the user
 * does not have to wait to have a feedback and, after that, to the backend.
 * The note id can be used to detect when the note is stored on the backend.
 */
export const onCreateNewNote = (
  message: string,
  issue: NoteIssue
): CadiusThunkAction<void> => {
  const pc = client();

  return async (
    dispatch: CadiusDispatch,
    getState: () => IApplicationState
  ): Promise<void> => {
    const localId = newLocalId();
    const { notes, projectId, view } = getState();
    const popover = notes.writeNotePopover;
    const cadView = view.cadView;
    const camera = cadView.saved();

    let position = new Vector3();
    if (popover) {
      position = popover.point;
      dispatch(addNote({ camera, id: localId, issue, message, position }));
    }

    const noteInput = {
      archived: false,
      camera: {
        position: cadView.position.toArray(),
        target: cadView.target.toArray(),
        zoomFactor: cadView.zoomFactor,
      },
      issue,
      issue_position: position.toArray(),
      message,
      username: getUserCurrentlyLoggedIn(),
    };

    await dispatch(showDialog(`Saving new note about ${issue}`, 0, "New Note"));

    try {
      const note = await pc.styles.addNote(projectId, noteInput);
      dispatch(replaceNoteId(localId, note.id));
      dispatch({ payload: { oldName: localId, newName: note.id }, type: act.RENAME_OBJECT_3D });
    } catch (err) {
      const info = "Failed to save the note to the backend.";
      const detail = err.toString();
      dispatch(appError(info, detail));
    } finally {
      await dispatch(hideDialog());
    }
  };
};

/**
 * Immediately remove a note from the application state.
 */
const removeNoteInUI = (noteId: string): CadiusThunkAction<void> => {
  return async (dispatch: CadiusDispatch): Promise<void> => {
    const object3D = noteAsObject3DMap.get(noteId);
    if (object3D) {
      dispatch({ payload: { name: noteId }, type: act.REMOVE_OBJECT_3D });
    }
    dispatch({ payload: { id: noteId }, type: act.REMOVE_NOTE });
  };
};

/**
 * Remove a note from the UI, the 3D scene and the backend.
 *
 * The note is immediately removed from the application state, and if the
 * network operation is succesfull, it is removed from the backend as well.
 */
export const removeNote = (projectId: string, noteId: string): CadiusThunkAction<void> => {

  const pc = client();

  return async (dispatch: CadiusDispatch): Promise<void> => {
    dispatch(removeNoteInUI(noteId));

    await dispatch(showDialog(`Removing note from project ${projectId}`, 0, "Remove Note"));
    try {
      const notes = await pc.styles.fetchNotes(projectId);
      const noteIndex = notes.findIndex((n) => n.id === noteId);
      if (noteIndex === -1) {
        throw new Error(`Note ${noteId} not found in project ${projectId}`);
      }
      await pc.styles.deleteNote(projectId, noteIndex);
      dispatch({ payload: { name: noteId }, type: act.REMOVE_OBJECT_3D });
    } catch (err) {
      const info = "Failed to remove the note from the backend.";
      const detail = err.toString();
      dispatch(appError(info, detail));
    } finally {
      await dispatch(hideDialog());
    }
  };
};

/**
 * Immediately add a comment to an existing note.
 *
 * Corner case: what to do when we have a note in the application state, but not
 * yet in the backend?
 */
const addCommentToNoteInUI: ActionCreator<IAction> = (
  noteId: string,
  commentId: string,
  message: string
) => {
  return { payload: { message, commentId, noteId }, type: act.ADD_COMMENT_TO_NOTE };
};

/**
 * Signal the app that the network request was successful, and update the
 * comment with the id coming from the API.
 */
const replaceCommentId: ActionCreator<IAction> = (noteId: string, localId: string, backendId: string) => {
  return { payload: { backendId, localId, noteId }, type: act.REPLACE_COMMENT_ID };
};

function mockBackendCommentId(note: Note) {
  const commentIndex = note.comments.length > 0 ? note.comments.length - 1 : 0;
  return `${note.id}-comment-index-${commentIndex}`;
}

/**
 * Add a comment to an existing note.
 */
export const addCommentToNote = (
  projectId: string,
  noteId: string,
  message: string
): CadiusThunkAction<void> => {
  const pc = client();
  return async (dispatch: CadiusDispatch): Promise<void> => {
    const localId = newLocalId();
    dispatch(addCommentToNoteInUI(noteId, localId, message));

    await dispatch(showDialog(`Adding comment to note ${noteId}`, 0, "Add Comment"));
    try {
      const notes = await pc.styles.fetchNotes(projectId);
      const noteIndex = notes.findIndex((n) => n.id === noteId);
      if (noteIndex === -1) {
        throw new Error(`Note ${noteId} not found in project ${projectId}`);
      }
      const comment = { message };
      const r = await pc.styles.addComment(projectId, noteId, comment);
      if (r) {
        // TODO: a uuid ID would be more appropriate, but at the moment the API
        // does NOT return an ID for a comment.
        const backendId = mockBackendCommentId(notes[noteIndex]);
        dispatch(replaceCommentId(noteId, localId, backendId));
      }
    } catch (err) {
      const info = `Failed to add comment to note ${noteId}.`;
      const detail = err.toString();
      dispatch(appError(info, detail));
    } finally {
      await dispatch(hideDialog());
    }
  };
};

/**
 * Immediately edit an existing comment in the UI.
 */
const editCommentInNoteInUI: ActionCreator<IAction> = (noteId: string, commentId: string, editedComment: string) => {
  return { payload: { commentId, editedComment, noteId }, type: act.EDIT_COMMENT_IN_NOTE };
};

/**
 * Edit an existing comment.
 */
export const editCommentInNote = (
  projectId: string,
  noteId: string,
  commentId: string,
  editedComment: string
): CadiusThunkAction<void> => {

  const pc = client();

  return async (dispatch: CadiusDispatch): Promise<void> => {
    dispatch(editCommentInNoteInUI(noteId, commentId, editedComment));

    await dispatch(showDialog(`Editing comment ${commentId} in note ${noteId}`, 0, "Edit Comment"));
    try {
      await pc.styles.updateComment(projectId, noteId, commentId, {
        message: editedComment,
      });
    } catch (err) {
      const info = "Failed to edit the comment in the note.";
      const detail = err.toString();
      dispatch(appError(info, detail));
    } finally {
      await dispatch(hideDialog());
    }
  };
};

/**
 * Immediately remove a comment from an existing note.
 *
 * Corner case: what to do when we have a note in the application state, but not
 * yet in the backend?
 */
const removeCommentFromNoteInUI: ActionCreator<IAction> = (noteId: string, commentId: string) => {
  return { payload: { commentId, noteId }, type: act.REMOVE_COMMENT_FROM_NOTE };
};

/**
 * Remove a comment from an existing note.
 */
export const removeCommentFromNote = (
  projectId: string,
  noteId: string,
  commentId: string
): CadiusThunkAction<void> => {
  const pc = client();
  return async (dispatch: CadiusDispatch): Promise<void> => {
    dispatch(removeCommentFromNoteInUI(noteId, commentId));

    await dispatch(showDialog(`Removing comment ${commentId} from note ${noteId}`, 0, "Remove Comment"));
    try {
      // TODO: make Picasso client accept noteId for deleteNote
      const notes = await pc.styles.fetchNotes(projectId);
      const noteIndex = notes.findIndex((n) => n.id === noteId);
      if (noteIndex === -1) {
        throw new Error(`Note ${noteId} not found in project ${projectId}`);
      }
      // TODO: this is NOT correct. This code removes the last comment, not the
      // one which was clicked. To delete the correct comment, either make
      // picasso client accept noteId and commentId for deleteComment, or use a
      // index for the data attribute data-message-id in the MessageView
      // component.
      const splits = mockBackendCommentId(notes[noteIndex]).split("-");
      const commentIndex = parseInt(splits[splits.length - 1], 10);
      await pc.styles.deleteComment(projectId, noteIndex, commentIndex);
    } catch (err) {
      const info = "Failed to remove the comment from the note.";
      const detail = err.toString();
      dispatch(appError(info, detail));
    } finally {
      await dispatch(hideDialog());
    }
  };
};

// Map that pairs a noteId with its 3D representation.
const noteAsObject3DMap = new Map<string, Object3D>();

/**
 * Archive a note in the application state and remove it from the 3D scene.
 */
export const archiveNoteInUI = (noteId: string): CadiusThunkAction<void> => {
  return async (dispatch: CadiusDispatch): Promise<void> => {
    if (noteAsObject3DMap.has(noteId)) {
      dispatch({ payload: { name: noteId }, type: act.REMOVE_OBJECT_3D });
    }
    dispatch({ payload: { id: noteId }, type: act.ARCHIVE_NOTE });
  };
};

/**
 * Archive a note to mark the issue as "fixed".
 */
export const archiveNote = (projectId: string, noteId: string): CadiusThunkAction<void> => {

  const pc = client();

  return async (dispatch: CadiusDispatch): Promise<void> => {
    dispatch(archiveNoteInUI(noteId));

    await dispatch(showDialog(`Archiving note ${noteId}`, 0, "Archive Note"));
    try {
      const note = await pc.styles.archiveNote(projectId, noteId);
      if (note) {
        log(`Archived note ${noteId} from project ${projectId}`);
      }
    } catch (err) {
      const info = `Failed to archive the note.`;
      const detail = err.toString();
      dispatch(appError(info, detail));
    } finally {
      await dispatch(hideDialog());
    }

  };
};

/**
 * Unarchive a note in the application state and add it to the 3D scene.
 */
export const unarchiveNoteInUI = (noteId: string): CadiusThunkAction<void> => {
  return async (dispatch: CadiusDispatch): Promise<void> => {
    const object3D = noteAsObject3DMap.get(noteId);
    if (object3D) {
      dispatch({ payload: { name: noteId, object3D }, type: act.ADD_OBJECT_3D });
    }
    dispatch({ payload: { id: noteId }, type: act.UNARCHIVE_NOTE });
  };
};

export const unarchiveNote = (projectId: string, noteId: string): CadiusThunkAction<void> => {

  const pc = client();

  return async (dispatch: CadiusDispatch): Promise<void> => {
    dispatch(unarchiveNoteInUI(noteId));

    await dispatch(showDialog(`Unarchiving note ${noteId}`, 0, "Unarchive Note"));
    try {
      const note = pc.styles.unarchiveNote(projectId, noteId);
      if (note) {
        log(`Unarchived note ${noteId} from project ${projectId}`);
      }
    } catch (err) {
      const info = `Failed to unarchive the note.`;
      const detail = err.toString();
      dispatch(appError(info, detail));
    } finally {
      await dispatch(hideDialog());
    }

  };
};

const makeNormalOnMeshByPoint = (mesh: HalfEdgeMeshLast) => {
  // Compute the normal that passes through the given point on a HalfEdgeMeshLast.
  return function normalOnMeshByPoint(point: Vector3) {
    const qr = mesh.nearestLoop(point);
    if (!qr) {
      throw new Error(`
      Point ${point} on this mesh returned no QueryHalfEdgeLoopResult.
      This mesh has ${mesh.numberOfClosedLoops()} closed loops.`);
    }
    return computePointNormalFromLoop(qr.closestPoint, qr.object);
  };
};

/**
 * Start fetching project notes and keep doing it every `ms` milliseconds.
 */
export const startAndKeepPollingNotes = (
  ms: number,
  projectId?: string
): CadiusThunkAction<void> => {

  const pc = client();

  return async (
    dispatch: CadiusDispatch,
    getState: () => IApplicationState
  ): Promise<void> => {

    const { project } = getState();

    const mesh = project.fundamentalEntities.last.geometry() as HalfEdgeMeshLast;
    const normalOnMeshByPoint = makeNormalOnMeshByPoint(mesh);

    // Update the 3D representation of a note in the scene, based on the current
    // status of the note itself.
    const updatePinInScene = (n: Note) => {
      const [x, y, z] = n.issue_position;
      const position = new Vector3(x, y, z);
      const color = getColor(n.issue);
      const normal = normalOnMeshByPoint(position);
      const object3D = toPin({ color: new Color(color), normal, position });
      object3D.name = n.id;

      if (noteAsObject3DMap.has(n.id)) {
        if (n.archived) {
          dispatch({ payload: { name: n.id }, type: act.REMOVE_OBJECT_3D });
        } else {
          dispatch({ payload: { name: n.id, object3D }, type: act.UPDATE_OBJECT_3D });
        }
      } else {
        noteAsObject3DMap.set(n.id, object3D);
        dispatch({ payload: { object3D }, type: act.ADD_OBJECT_3D });
      }
    };

    let cb: () => Promise<void>;
    if (projectId) {
      // Give this callback a name, so it's easier to spot if we need to profile
      // https://developers.google.com/web/tools/chrome-devtools/memory-problems/
      // heap-snapshots?authuser=0#containment_view
      cb = async function fetchAndSyncNotes() {
        dispatch({ payload: { isFetching: true }, type: act.SET_NOTES_FETCHING });
        try {
          const notesFromAPI = await pc.styles.fetchNotes(projectId);
          notesFromAPI.forEach(updatePinInScene);

          // Some of the notes that we have just fetched from the API might have
          // been already saved in the app's state, but they have a different id
          // (a "local" id instead of a "backend" id). That's why we need to
          // reconcile the notes in the app's state.
          dispatch({ payload: { notesFromAPI }, type: act.SYNC_NOTES });
        } catch (err) {
          const info = "Failed to fetch notes.";
          const detail = err.toString();
          dispatch(appError(info, detail));
        } finally {
          dispatch({ payload: { isFetching: false }, type: act.SET_NOTES_FETCHING });
        }
      };
    } else {
      cb = async function fetchAndSyncNotes() { };
    }

    // Immediately fetch and sync notes...
    await cb();
    // ...then keep doing it every `ms` milliseconds
    const intervalTimer = setInterval(cb, ms);
    dispatch({ payload: { intervalTimer }, type: act.START_POLLING_NOTES });
  };
};

/**
 * Stop polling notes and remove the 3D representation of each note from scene.
 */
export const stopPollingNotes = (): CadiusThunkAction<void> => {
  return async (dispatch: CadiusDispatch) => {
    for (const noteId of noteAsObject3DMap.keys()) {
      dispatch({ payload: { name: noteId }, type: act.REMOVE_OBJECT_3D });
    }
    noteAsObject3DMap.entries();
    dispatch({ type: act.STOP_POLLING_NOTES });
  };
};
