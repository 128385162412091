import { MaterialDB, ProjectDigest } from "cadius-backend";
import { RenderManager, Size } from "cadius-cadlib";
import { RenderingRefreshCallback } from "cadius-components";
import { Reducer } from "redux";
import { Scene } from "three";

import { RESET_INITIAL_STATE } from "../actions";
import { IAction, IApplicationState, UiMode } from "../interfaces";
import { initialState as almostAllInitialState, reducer as almostAllReducer } from "./almost-all";
import { reducer as glReducer } from "./gl";
import { interactorStackMap, reducer as interactorStackReducer } from "./interactor-stack";
import { initialState as materialsInitialState, reducer as materialsReducer } from "./materials";
import { initialState as notesInitialState, reducer as notesReducer } from "./notes";
import { initialState as filtersInitialState, reducer as filtersReducer } from "./render-context-filters";

function initialState(
  projectId: string = "",
  renderManger: RenderManager = new RenderManager(true, new Scene()),
  projectName?: string,
  remeshingProjectName?: string,
  materialDB?: MaterialDB,
  projectDigests?: ProjectDigest[],
  subscribedRefreshers?: Set<RenderingRefreshCallback>,
  cadViewSize?: Size,
  refresh?: () => Promise<void>
): IApplicationState {
  return {
    ...materialsInitialState(),
    ...almostAllInitialState(
      renderManger,
      materialDB,
      projectDigests,
      projectId,
      projectName,
      remeshingProjectName,
      subscribedRefreshers,
      cadViewSize,
      refresh
    ),
    filters: filtersInitialState(),
    interactors: interactorStackMap[UiMode.CHANGE_MATERIAL],
    notes: notesInitialState(),
  };
}

export const rootReducer: Reducer<IApplicationState, IAction> = (
  s0: IApplicationState = initialState(),
  action: IAction
): IApplicationState => {
  if (action.type === RESET_INITIAL_STATE) {
    return initialState(
      s0.projectId,
      s0.renderManager,
      s0.projectName,
      s0.remeshingProjectName,
      s0.materialDB,
      s0.projectDigests,
      s0.subscribedRefreshers,
      s0.view.cadView.size,
      s0.refresh
    );
  }

  const filters = filtersReducer(s0.filters, action);

  const materialsState = materialsReducer({
    indexSelectedMaterial: s0.indexSelectedMaterial,
    isFetching: s0.isFetching,
  }, action);

  const notes = notesReducer(s0.notes, action);

  const almostAllState = almostAllReducer({
    ...s0, filters,
  }, action);

  const s1 = interactorStackReducer({
    ...almostAllState,
    ...materialsState,
    interactors: s0.interactors,
    notes,
  }, action);

  const state = glReducer(s1, action);

  return state;
};
