import { Note } from "cadius-backend";
import { ScreenPosition } from "cadius-cadlib";
import { BaseInteractor, Cad, DEFAULT_SNAP_RADIUS_PX } from "cadius-components";

import * as act from "../actions";
import { Cad3DAppControl } from "../controls/controls";
import {
  CadiusDispatch,
  CadiusThunkAction,
  IApplicationState,
} from "../interfaces";
import { dispatch } from "../store";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const nopControl = new Cad3DAppControl((state: IApplicationState, v: any) => state);

const INTERACTOR_KIND = "PickNote";

/**
 * Schedule to open the note popup as soon as possible.
 *
 * This action is dispatched by an interactor. Like all interactors, this one is
 * inside our reducing cycle. Since we are inside a reducer, we can't directly
 * dispatch an action, but we can push a callback on the callback queue of the
 * JS event loop. The actual action that we want to perform will be executed as
 * soon as the call stack of the JS event loop is empty.
 *
 * @see https://jakearchibald.com/2015/tasks-microtasks-queues-and-schedules/
 */
const showPreviewNote = (pos: ScreenPosition, note: Note): CadiusThunkAction<void> => {
  return async (cadiusDispatch: CadiusDispatch): Promise<void> => {
    setTimeout(() => {
      cadiusDispatch({ payload: { note, pos }, type: act.OPEN_POPUP_NOTE });
    }, 1);
  };
};

/**
 * Schedule to close the note popup as soon as possible.
 */
const closePreviewNote = (): CadiusThunkAction<void> => {
  return async (cadiusDispatch: CadiusDispatch): Promise<void> => {
    setTimeout(() => {
      cadiusDispatch({ type: act.CLOSE_POPUP_NOTE });
    }, 1);
  };
};

export class PickNoteInteractor extends BaseInteractor<any> {
  private _snapRadiusPx: number;

  constructor(snapRadiusPx: number = DEFAULT_SNAP_RADIUS_PX) {
    super(INTERACTOR_KIND, nopControl);
    this._snapRadiusPx = snapRadiusPx;
  }

  protected onMouseClickEvent(evt: Cad.MouseClickEvent) {
    if (!evt.hasNoModifier()) {
      return false;
    }

    dispatch(closePreviewNote());

    const s = Cad3DAppControl.state;

    let ray = s.view.cadView.castRay(evt.position);
    const intersectionOnLast = s.project.fundamentalEntities.last.geometry().intersectLoopPoint(ray);

    if (!intersectionOnLast) {
      return true;
    }

    ray = ray.clone(
      ray.origin.distanceTo(intersectionOnLast) * 1.02,
      s.view.cadView.computeActionRadius(intersectionOnLast, evt.position, this._snapRadiusPx)
    );

    const qr = s.notes.noteGeoIndex.intersectRay(ray);

    for (const r of qr) {
      const note = s.notes.notes.find((n) => n.id === r.object.id);
      if (note && !note.archived) {
        dispatch(showPreviewNote(evt.position, note));
        return true;
      }
    }

    return true;
  }
}
