import { connect } from "react-redux";

import { onNewMaterial } from "../actions/materials";
import { selectNewNotePosition } from "../actions/notes";
import { fetchProject, setProjectData } from "../actions/projects";
import {
  toggleDecorations,
  toggleLast,
  toggleLines,
  togglePieces,
  toggleWireframe,
} from "../actions/toggles";
import { ProjectNewRoute } from "../components/ProjectNewRoute";
import { IApplicationState } from "../interfaces";

function mapStateToProps(state: IApplicationState) {
  const {
    dialog,
    filters,
    indexSelectedMaterial,
    project,
    projectId,
    projectName,
    remeshingProjectName,
    uiMode,
  } = state;

  return {
    dialog,
    filters,
    indexSelectedMaterial,
    project,
    projectId,
    remeshingProjectName,
    styleProjectName: projectName,
    uiMode,
  };
}

const mapDispatchToProps = {
  fetchProject,
  onNewMaterial,
  selectNewNotePosition,
  setProjectId: setProjectData,
  toggleDecorations,
  toggleLast,
  toggleLines,
  togglePieces,
  toggleWireframe,
};

const enhance = connect(
  mapStateToProps,
  mapDispatchToProps
);

/**
 * A `ProjectNewRoute` component connected to a redux store, so all of its props
 * are either taken from the application global state, or perform actions on the
 * redux store.
 */
export const ProjectNewRouteConnected = enhance(ProjectNewRoute);
