import { Radio } from "cadius-components";
import clsx from "clsx";
import React, { useState } from "react";

import { NoteIssue } from "../interfaces";

import "./new-note.scss";

interface IProps {
  onCreateNewNote: (content: string, issue: NoteIssue) => void;
  onDismissCurrentNote: () => void;
  projectId: string;
}

const NEW_NOTE_FORM_ID = "new-note-form";
const CANCEL_BUTTON_TEXT = "Cancel";
const CONFIRM_BUTTON_TEXT = "Add";
const TEXTAREA_PLACEHOLDER = "Annotate suggested changes here…";

export const NewNote: React.FC<IProps> = props => {
  const { onCreateNewNote, onDismissCurrentNote, projectId } = props;

  console.log("NewNote.projectId", projectId);

  const [content, setContent] = useState("");
  const [issue, setIssue] = useState<NoteIssue>("material");

  const onNoteChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setContent(event.target.value);
  };

  const onChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIssue(event.target.value as NoteIssue);
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onCreateNewNote(content, issue);
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.ctrlKey && event.key === "Enter") {
      onCreateNewNote(content, issue);
    }
  };

  const onClickCancel = () => {
    onDismissCurrentNote();
  };

  return (
    <form
      className={clsx("new-note-form", "stack-dense")}
      id={NEW_NOTE_FORM_ID}
      onKeyDown={onKeyDown}
      onSubmit={onSubmit}
    >
      <h2>Write a note</h2>
      <textarea
        autoFocus={true}
        form={NEW_NOTE_FORM_ID}
        name={"note-textarea"}
        onChange={onNoteChange}
        placeholder={TEXTAREA_PLACEHOLDER}
        value={content}
      />
      <p>Select the issue category</p>
      <div>
        <Radio
          checked={issue === "material"}
          choice={"material"}
          onChange={onChangeRadio}
        />
        <Radio
          checked={issue === "geometry"}
          choice={"geometry"}
          onChange={onChangeRadio}
        />
      </div>
      <div className="actions">
        <button className="ghost-button" onClick={onClickCancel} type="button">
          {CANCEL_BUTTON_TEXT}
        </button>
        <button
          className={clsx("primary-button", !content.length && "disabled")}
          disabled={!content.length}
          type="submit"
        >
          {CONFIRM_BUTTON_TEXT}
        </button>
      </div>
    </form>
  );
};
