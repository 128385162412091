import { connect } from "react-redux";

import { changeUiMode } from "../actions/ui";
import { Tabs } from "../components/Tabs";
import { IApplicationState } from "../interfaces";

function mapStateToProps(state: IApplicationState) {
  const { uiMode } = state;

  return {
    uiMode,
  };
}

// mapDispatchToProps shorthand syntax
// https://react-redux.js.org/using-react-redux/connect-mapdispatch#two-forms-of-mapdispatchtoprops
const mapDispatchToProps = { changeUiMode };

const enhance = connect(
  mapStateToProps,
  mapDispatchToProps
);

export const TabsConnected = enhance(Tabs);
