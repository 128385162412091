import { Reducer } from "redux";

import * as act from "../actions";
import { IAction, IMaterialsState } from "../interfaces";

export const reducer: Reducer<IMaterialsState, IAction> = (
  state = initialState(),
  action
): IMaterialsState => {
  switch (action.type) {
    case act.FAILED_TO_INIT_APP: {
      return {
        ...state,
        indexSelectedMaterial: undefined,
      };
    }

    case act.SELECT_MATERIAL: {
      const { index } = action.payload;
      return {
        ...state,
        indexSelectedMaterial: index,
      };
    }

    case act.SET_PROJECT: {
      return {
        ...state,
        indexSelectedMaterial: undefined,
      };
    }

    case act.SET_MATERIALS_FETCHING: {
      const { isFetching } = action.payload;
      return {
        ...state,
        isFetching,
      };
    }

    default:
      return state;
  }
};

export const initialState = (): IMaterialsState => {
  return {
    indexSelectedMaterial: undefined,
    isFetching: false,
  };
};

export const getIndexSelectedMaterial = (state: { indexSelectedMaterial?: number }) => {
  if (state.indexSelectedMaterial !== undefined) {
    return state.indexSelectedMaterial;
  } else {
    return undefined;
  }
};
