import { Render3DContextFilters } from "cadius-cadlib";
import { Reducer } from "redux";

import * as act from "../actions";
import { IAction } from "../interfaces";

export const reducer: Reducer<Render3DContextFilters, IAction> = (
  state = initialState(),
  action
): Render3DContextFilters => {
  switch (action.type) {
    case act.TOGGLE_LAST:
      return { ...state, showLast: !state.showLast };

    case act.TOGGLE_LINES:
      return { ...state, showLines: !state.showLines };

    case act.TOGGLE_PIECES:
      return { ...state, showPieces: !state.showPieces };

    case act.TOGGLE_WIREFRAME:
      return { ...state, showWireframe: !state.showWireframe };

    case act.TOGGLE_DECORATIONS:
      return { ...state, showDecorations: !state.showDecorations };

    default:
      return state;
  }
};

export const initialState = (): Render3DContextFilters => {
  return {
    showDecorations: false,
    showLast: true,
    showLines: true,
    showPieces: true,
    showWireframe: false,
  };
};
