export const SET_ENV_MAP_SPECS = "SET_ENV_MAP_SPECS";

export const SUBSCRIBE_RENDERING_REFRESHER = "SUBSCRIBE_RENDERING_REFRESHER";
export const UNSUBSCRIBE_RENDERING_REFRESHER = "UNSUBSCRIBE_RENDERING_REFRESHER";
export const SET_REFRESHER = "SET_REFRESHER";
export const REFRESH_RENDERING = "REFRESH_RENDERING";

export const SELECT_MATERIAL = "SELECT_MATERIAL";

export const ADD_ENTITY = "ADD_ENTITY";
export const SET_ENV_MAP = "SET_ENV_MAP";
export const SET_PROJECT = "SET_PROJECT";
export const SET_PROJECT_DATA = "SET_PROJECT_DATA";

export const SAVE_PROJECT = "SAVE_PROJECT";
export const CONVERT_CAL_INTO_PROJECT = "CONVERT_CAL_INTO_PROJECT";
export const UPDATE_DIALOG = "UPDATE_DIALOG";

export const TOGGLE_DECORATIONS = "TOGGLE_DECORATIONS";
export const TOGGLE_LAST = "TOGGLE_LAST";
export const TOGGLE_LINES = "TOGGLE_LINES";
export const TOGGLE_PIECES = "TOGGLE_PIECES";
export const TOGGLE_WIREFRAME = "TOGGLE_WIREFRAME";

export const HANDLE_CAD_EVENT = "HANDLE_CAD_EVENT";
export const INVALID_DOM_EVENT = "INVALID_DOM_EVENT";
export const PUSH_TO_INTERACTOR_STACK = "PUSH_TO_INTERACTOR_STACK";
export const POP_FROM_INTERACTOR_STACK = "POP_FROM_INTERACTOR_STACK";

export const FETCH_PROJECTS_SUCCEEDED = "FETCH_PROJECTS_SUCCEEDED";

export const DEMO_ROTATE = "DEMO_ROTATE";

export const FAILED_TO_INIT_APP = "FAILED_TO_INIT_APP";
export const FAILED_TO_FETCH_MATERIALS = "FAILED_TO_FETCH_MATERIALS";
export const APP_ERROR = "APP_ERROR";

export const ADD_DEBUG_OBJECTS = "ADD_DEBUG_OBJECTS";
export const CLEAR_DEBUG_VIEW = "CLEAR_DEBUG_VIEW";
export const RESTORE_CAMERA = "RESTORE_CAMERA";
export const SET_CAMERA_SIZE = "SET_CAMERA_SIZE";

export const ADD_NOTE = "ADD_NOTE";
export const REMOVE_NOTE = "REMOVE_NOTE";
export const ARCHIVE_NOTE = "ARCHIVE_NOTE";
export const UNARCHIVE_NOTE = "UNARCHIVE_NOTE";
export const REPLACE_NOTE_ID = "REPLACE_NOTE_ID";
export const SET_NOTES_FETCHING = "SET_NOTES_FETCHING";

export const ADD_COMMENT_TO_NOTE = "ADD_COMMENT_TO_NOTE";
export const EDIT_COMMENT_IN_NOTE = "EDIT_COMMENT_IN_NOTE";
export const REMOVE_COMMENT_FROM_NOTE = "REMOVE_COMMENT_FROM_NOTE";
export const REPLACE_COMMENT_ID = "REPLACE_COMMENT_ID";

export const START_POLLING_NOTES = "START_POLLING_NOTES";
export const STOP_POLLING_NOTES = "STOP_POLLING_NOTES";

export const DISMISS_CURRENT_NOTE = "DISMISS_CURRENT_NOTE";
export const START_WRITING_NOTE = "START_WRITING_NOTE";

export const OPEN_POPUP_NOTE = "OPEN_POPUP_NOTE";
export const CLOSE_POPUP_NOTE = "CLOSE_POPUP_NOTE";
export const SELECT_NOTE = "SELECT_NOTE";

export const CHANGE_UI_MODE = "CHANGE_UI_MODE";

export const SET_PROJECT_DIGESTS = "SET_PROJECT_DIGESTS";

export const RESET_INITIAL_STATE = "RESET_INITIAL_STATE";

export const SET_MATERIALS = "SET_MATERIALS";
export const SET_MATERIAL_DB = "SET_MATERIAL_DB";
export const SET_MATERIALS_FETCHING = "SET_MATERIALS_FETCHING";
export const SYNC_NOTES = "SYNC_NOTES";

export const REFRESH_SOURCES = "REFRESH_SOURCES";

export const ADD_OBJECT_3D = "ADD_OBJECT_3D";
export const REMOVE_OBJECT_3D = "REMOVE_OBJECT_3D";
export const UPDATE_OBJECT_3D = "UPDATE_OBJECT_3D";
export const RENAME_OBJECT_3D = "RENAME_OBJECT_3D";

export const REGISTER_RENDERING_FUNCTION = "REGISTER_RENDERING_FUNCTION";
