import { connect } from "react-redux";

import {
  addCommentToNote,
  archiveNote,
  editCommentInNote,
  onCreateNewNote,
  removeCommentFromNote,
  removeNote,
  startAndKeepPollingNotes,
  stopPollingNotes,
  unarchiveNote,
} from "../actions/notes";
import { Notes } from "../components/Notes";
import { IApplicationState } from "../interfaces";
import { getProjectId } from "../reducers/almost-all";

function mapStateToProps(state: IApplicationState) {
  const notes = state.notes.notes;
  const projectId = getProjectId(state);

  return {
    notes,
    projectId,
  };
}

// mapDispatchToProps shorthand syntax
// https://react-redux.js.org/using-react-redux/connect-mapdispatch#two-forms-of-mapdispatchtoprops
const mapDispatchToProps = {
  addCommentToNote,
  archiveNote,
  editCommentInNote,
  onCreateNewNote,
  removeCommentFromNote,
  removeNote,
  startAndKeepPollingNotes,
  stopPollingNotes,
  unarchiveNote,
};

const enhance = connect(
  mapStateToProps,
  mapDispatchToProps
);

/**
 * A `Notes` component connected to a redux store, so all of its props are
 * either taken from the application global state, or perform actions on the
 * redux store.
 */
export const NotesConnected = enhance(Notes);
