import { BaseInteractor, Cad, isReactiveCadiusSceneWithRenderManager } from "cadius-components";
import { MaterializedSolid, SolidMaterial, TextureTransform } from "cadius-db";
import { Vector2 } from "three";

import { Cad3DAppControl } from "../controls/controls";
import { IApplicationState } from "../interfaces";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const nopControl = new Cad3DAppControl((state: IApplicationState, v: any) => state);

export class SelectorInteractor extends BaseInteractor<any> {
  private isActive: boolean;

  constructor() {
    super("Selector", nopControl);
    this.isActive = true;
  }

  protected onDragLeaveEvent(evt: Cad.DragLeaveEvent) {
    if (!this.isActive) {
      return false;
    }

    if (!evt.hasNoModifier() || !evt.hasOnlyButtons(Cad.MouseButton.LEFT)) {
      return false;
    }

    const newState = this.maybePickAndReplacePieceSolid(evt, Cad3DAppControl.state);
    if (Cad3DAppControl.state !== newState) {
      Cad3DAppControl.state = newState;
    }
    // Whether we have replaced a piece or not, we have handled this event
    // nonetheless. That's why we return true.
    return true;
  }

  protected onLeftClickEvent(evt: Cad.MouseClickEvent) {
    if (!this.isActive) {
      return false;
    }

    if (!evt.hasNoModifier() || !evt.hasOnlyButtons(Cad.MouseButton.LEFT)) {
      return false;
    }

    const newState = this.maybePickAndReplacePieceSolid(evt, Cad3DAppControl.state);
    if (newState !== Cad3DAppControl.state) {
      Cad3DAppControl.state = newState;
    }
    // Whether we have replaced a piece or not, we have handled this event
    // nonetheless. That's why we return true.
    return true;
  }

  /**
   * Cast a ray and find if it hits the last. If it does, find if it intersects
   * any PieceSolid in the scene.
   */
  private maybePickAndReplacePieceSolid(
    evt: Cad.DragLeaveEvent | Cad.MouseClickEvent,
    state: IApplicationState
  ): IApplicationState {
    const { indexSelectedMaterial, project, view } = state;

    let newState: IApplicationState = state;
    if (project && indexSelectedMaterial !== undefined) {
      let ray = view.cadView.castRay(evt.position);
      const intersectionOnLast = project.fundamentalEntities.last.geometry().intersectLoopPoint(ray);
      if (!intersectionOnLast) {
        return state;
      }
      ray = ray.clone(ray.origin.distanceTo(intersectionOnLast) * 1.02);

      const qr = state.solidScene.intersect(ray);

      if (qr.length > 0) {
        const selectedSolid: MaterializedSolid = qr[0].solid;

        // TODO extract the scale information from the PBR material
        const defaultScaleFactor = 1 / 128;
        const scale = new Vector2(defaultScaleFactor, defaultScaleFactor);

        const frontTextureTransform = new TextureTransform(undefined, undefined, scale);
        const backTextureTransform = new TextureTransform(undefined, undefined, scale);
        const sideTextureTransform = new TextureTransform(undefined, undefined, scale);

        const materialDB = state.materialDB!;
        const material = materialDB.materials[indexSelectedMaterial];

        const sm = new SolidMaterial(
          material.id,
          material.thickness,
          frontTextureTransform,
          backTextureTransform,
          sideTextureTransform
        );

        const newSolid = selectedSolid.clone({ material: sm });
        const ctx = {
          debug: newState.debug,
          envMaps: newState.envMap,
          filters: newState.filters,
          refresh: newState.refresh,
        };

        const newProject = project.replace(selectedSolid, newSolid);
        if (!isReactiveCadiusSceneWithRenderManager(newState.view.scene)) {
          throw new Error("ASSERT: Selector interactor should use the new CadiusSceneWithRenderManager");
        }

        newState.view.scene.update([...newProject], ctx);

        const solidScene = state.solidScene;
        solidScene.delete(selectedSolid);
        solidScene.add(newSolid.geometry(), newSolid);

        newState = { ...state, project: newProject, solidScene };
      }
    }

    return newState;
  }
}
