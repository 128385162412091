import { Object3D } from "three";

import * as act from "../actions";
import { IAction, IApplicationState } from "../interfaces";

/**
 * Reducer that manipulates WebGL objects.
 *
 * We don't use the `Reducer<State, Action>` type because for that type `state`
 * could be `undefined`. That would be correct in a generic Redux application,
 * but we are calling this reducer when we are sure that `state` is defined.
 */
export const reducer = (
  state: IApplicationState,
  action: IAction
): IApplicationState => {
  switch (action.type) {

    case act.ADD_OBJECT_3D: {
      const { object3D } = action.payload as { object3D: Object3D };
      getGLScene(state).add(object3D);
      return forceReRender(state);
    }

    case act.REMOVE_OBJECT_3D: {
      const { name } = action.payload as { name: string };
      const object3D = getGLScene(state).getObjectByName(name);
      if (object3D) {
        getGLScene(state).remove(object3D);
      }
      return forceReRender(state);
    }

    case act.UPDATE_OBJECT_3D: {
      const { name, object3D } = action.payload as { name: string, object3D: Object3D };
      // TODO: removing and object3D and adding a brand new one is convenient,
      // but it would be arguably better - from a performance standpoint - to
      // update in-place the selected object3D properties (i.e. position,
      // rotation, etc).
      const oldObject3D = getGLScene(state).getObjectByName(name);
      if (oldObject3D) {
        getGLScene(state).remove(oldObject3D);
      }
      getGLScene(state).add(object3D);
      return forceReRender(state);
    }

    case act.RENAME_OBJECT_3D: {
      const { oldName, newName } = action.payload as { oldName: string, newName: string };
      const object3D = getGLScene(state).getObjectByName(oldName);
      if (object3D) {
        object3D.name = newName;
      }
      return forceReRender(state);
    }

    default:
      return state;
  }
};

/**
 * Destructure `view` to cause a re-render of the components that have a
 * Three.js scene in `view` (*e.g.* the `ViewConnected` component).
 */
const forceReRender = (state: IApplicationState) => {
  return {
    ...state,
    view: {
      ...state.view,
    },
  };
};

const getGLScene = (state: IApplicationState) => {
  return state.view.scene.getGLScene();
};
