import { connect } from "react-redux";

import { dismissCurrentNote, onCreateNewNote, selectNote } from "../actions/notes";
import { ProjectView } from "../components/ProjectView";
import { IApplicationState } from "../interfaces";

function mapStateToProps(state: IApplicationState) {
  const previewNotePopover = state.notes.previewNotePopover;
  const projectId = state.projectId;
  const writeNotePopover = state.notes.writeNotePopover;
  // const popoverPosition = popover ? popover.pos : undefined;

  return {
    previewNotePopover,
    projectId,
    writeNotePopover,
  };
}

const mapDispatchToProps = {
  dismissCurrentNote,
  onCreateNewNote,
  selectNote,
};

const enhance = connect(
  mapStateToProps,
  mapDispatchToProps
);

/**
 * A `ProjectView` component connected to a redux store, so all of its props are
 * either taken from the application global state, or perform actions on the
 * redux store.
 */
export const ProjectViewConnected = enhance(ProjectView);
