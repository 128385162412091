import { MaterialDB, Note, ProjectDigest } from "cadius-backend";
import {
  CameraOrientation,
  IndexedSolidMeshScene,
  makeView,
  RenderManager,
  SavedCamera,
  Size,
} from "cadius-cadlib";
import {
  isReactiveCadiusSceneWithRenderManager,
  ReactiveView,
  RenderingRefreshCallback,
} from "cadius-components";
import { isLast, isSolid, newEmptyProject } from "cadius-db";
import { actions as debugActions, reducers as debugReducers } from "cadius-debug";
import { debugLines } from "cadius-visual-debug";
import { Reducer } from "redux";
import { Scene, Vector3 } from "three";

import * as act from "../actions";
import { IAction, IAlmostAllApplicationState, UiMode } from "../interfaces";
import { ProjectManager } from "../projectManager";
// import { EntitiesSource, isEntitiesSource } from "../sources/entities";
// import { getInitialSources } from "../utils";
import { makeScene } from "../view-utils";
import { initialState as filtersInitialState } from "./render-context-filters";

const initialViewId = 0;

export function initialState(
  renderManager: RenderManager = new RenderManager(true, new Scene()),
  materialDB?: MaterialDB,
  projectDigests: ProjectDigest[] = [],
  projectId: string = "",
  projectName?: string,
  remeshingProjectName?: string,
  subscribedRefreshers?: Set<RenderingRefreshCallback>,
  cadViewSize?: Size,
  refresh: () => Promise<void> = () => Promise.resolve()
): IAlmostAllApplicationState {
  const filters = filtersInitialState();

  const cadView = new ReactiveView(makeView(CameraOrientation.perspective))
  if (cadViewSize) {
    cadView.resize(cadViewSize);
  }

  return {
    debug: debugReducers.initialState(),
    debugLinesVersion: debugLines.version,
    dialog: { open: false, title: "" },
    envMap: null,
    envMapsSpecs: [],
    filters,
    intervalId: -1,
    lastEvent: Date.now(),
    materialDB,
    project: newEmptyProject(new ProjectManager(projectId)),
    projectDigests,
    projectId,
    projectName,
    refresh,
    remeshingProjectName,
    renderManager,
    solidScene: new IndexedSolidMeshScene(),
    subscribedRefreshers: subscribedRefreshers ?? new Set<RenderingRefreshCallback>(),
    uiMode: UiMode.CHANGE_MATERIAL,
    view: {
      cadView,
      id: initialViewId,
      scene: makeScene(renderManager, undefined, undefined, undefined, CameraOrientation.perspective, filters),
    },
  };
}

export const reducer: Reducer<IAlmostAllApplicationState, IAction> = (
  state: IAlmostAllApplicationState = initialState(),
  action: IAction
): IAlmostAllApplicationState => {
  switch (action.type) {
    case act.REGISTER_RENDERING_FUNCTION: {
      const { entityName, render } = action.payload;
      if (!isReactiveCadiusSceneWithRenderManager(state.view.scene)) {
        throw new Error("ASSERT: cad3dr app should use the new ReactiveCadiusSourceWithRenderManager");
      }
      state.view.scene.getRenderManager().register(entityName, render);
      return {
        ...state,
        view: {
          ...state.view,
          scene: state.view.scene.touch({
            debug: state.debug,
            envMaps: null,
            filters: state.filters,
            refresh: state.refresh,
          }),
        },
      };
    }

    case act.SET_ENV_MAP_SPECS: {
      return {
        ...state,
        envMapsSpecs: action.payload.specs,
      };
    }

    case act.SUBSCRIBE_RENDERING_REFRESHER: {
      const subscribedRefreshers = state.subscribedRefreshers;
      subscribedRefreshers.add(action.payload!.refreshCallback);
      return {
        ...state,
        subscribedRefreshers,
      };
    }

    case act.UNSUBSCRIBE_RENDERING_REFRESHER: {
      const subscribedRefreshers = state.subscribedRefreshers;
      subscribedRefreshers.delete(action.payload!.refreshCallback);
      return {
        ...state,
        subscribedRefreshers,
      };
    }

    case act.SET_REFRESHER: {
      return {
        ...state,
        refresh: action.payload!.refresh,
      };
    }

    case act.SET_ENV_MAP: {
      // TODO enable the env map

      return {
        ...state,
        envMap: action.payload.envmap,
      };
    }

    case act.SET_MATERIAL_DB: {
      const { materialDB } = action.payload;
      return {
        ...state,
        materialDB,
      };
    }

    case act.SET_PROJECT: {
      if (!state.materialDB) {
        throw new Error("Cannot set a project without setting a MaterialDB first");
      }

      state.solidScene.clear();

      for (const entity of action.payload.project) {
        if (
          isSolid(entity) &&
          entity !== action.payload.project.fundamentalEntities.last
        ) {
          state.solidScene.add(entity.geometry(), entity);
        }
      }

      return {
        ...state,
        dialog: state.dialog,
        project: action.payload.project,
        projectId: action.payload.projectId,
        solidScene: state.solidScene,
        view: {
          ...state.view,
          scene: state.view.scene.update(action.payload.project, {
            debug: state.debug,
            envMaps: null,
            filters: state.filters,
            refresh: state.refresh,
          }),
        },
      };
    }

    case act.ADD_ENTITY: {
      const entity = action.payload!.entity;
      const project = state.project.add(entity);

      if (isSolid(entity) && !isLast(entity)) {
        state.solidScene.add(entity.geometry(), entity);
      }

      if (!isReactiveCadiusSceneWithRenderManager(state.view.scene)) {
        throw new Error("ASSERT: cad3dr app should use the new ReactiveCadiusSourceWithRenderManager");
      }

      state.view.scene.update([...project], {
        debug: state.debug,
        envMaps: null,
        filters: state.filters,
        refresh: state.refresh,
      });

      return {
        ...state,
        project,
      };
    }

    case act.SELECT_NOTE: {
      const { id, notes } = action.payload;
      const note = notes.find((n: Note) => n.id === id);

      let cadView = state.view.cadView;
      if (note) {
        const { position, target, zoomFactor } = note.camera as {
          position: picasso.Vector3,
          target: picasso.Vector3,
          zoomFactor?: number,
        };
        const [px, py, pz] = position;
        const [tx, ty, tz] = target;
        cadView = cadView.setPosition(new Vector3(px, py, pz), new Vector3(tx, ty, tz));
        cadView.zoom(zoomFactor || 1);
      }

      return {
        ...state,
        view: {
          ...state.view,
          cadView,
        },
      };
    }

    case act.SET_PROJECT_DATA: {
      return {
        ...state,
        projectId: action.payload.projectId,
        projectName: action.payload.projectName,
        remeshingProjectName: action.payload.remeshingProjectName,
      };
    }

    case act.SAVE_PROJECT:
      return {
        ...state,
        project: action.payload.project,
        projectId: action.payload.id,
      };

    case act.CONVERT_CAL_INTO_PROJECT: {
      const { project } = action.payload;
      return {
        ...state,
        project,
      };
    }

    case act.UPDATE_DIALOG: {
      const { info, open, progress, title } = action.payload;
      return {
        ...state,
        dialog: { info, open, progress, title: title ? title : "" },
      };
    }

    case act.FAILED_TO_INIT_APP: {
      return {
        ...state,
        dialog: {
          info: action.payload.info,
          open: true,
          progress: undefined,
          title: "Failed to init app",
        },
        envMap: null,
        materialDB: undefined,
      };
    }

    case act.FAILED_TO_FETCH_MATERIALS: {
      return {
        ...state,
        dialog: {
          info: action.payload.info,
          open: true,
          progress: undefined,
          title: "Failed to fetch materials",
        },
      };
    }

    case act.SET_CAMERA_SIZE: {
      const size = action.payload as Size;
      return {
        ...state,
        view: {
          ...state.view,
          cadView: state.view.cadView.resize(size),
        },
      };
    }

    case act.RESTORE_CAMERA: {
      const saved = action.payload.saved as SavedCamera;
      return {
        ...state,
        view: {
          ...state.view,
          cadView: state.view.cadView.restore(saved),
        }
      };
    }

    case act.APP_ERROR:
      console.log("APP_ERROR", action.payload);
      return {
        ...state,
      };

    case debugActions.ADD_DEBUG_OBJECTS:
    case debugActions.CLEAR_DEBUG_VIEW: {
      const debug = debugReducers.reduce(state.debug, action);

      let newScene = state.view.scene;
      if (!isReactiveCadiusSceneWithRenderManager(state.view.scene)) {
        throw new Error("ASSERT: cad3dr app should use the new ReactiveCadiusSourceWithRenderManager");
      }
      try {
        newScene = state.view.scene.update([...state.project], {
          debug,
          envMaps: null,
          filters: state.filters,
          refresh: state.refresh,
        });
      } catch { }

      return {
        ...state,
        debug,
        view: {
          ...state.view,
          scene: newScene,
        },
      };
    }

    case act.TOGGLE_DECORATIONS:
    case act.TOGGLE_LAST:
    case act.TOGGLE_LINES:
    case act.TOGGLE_PIECES:
    case act.TOGGLE_WIREFRAME:
    case act.REFRESH_SOURCES: {
      let newScene = state.view.scene;
      if (!isReactiveCadiusSceneWithRenderManager(state.view.scene)) {
        throw new Error("ASSERT: cad3dr app should use the new ReactiveCadiusSourceWithRenderManager");
      }
      try {
        newScene = state.view.scene.touch({
          debug: state.debug,
          envMaps: null,
          filters: state.filters,
          refresh: state.refresh,
        });
      } catch { }

      return {
        ...state,
        view: {
          ...state.view,
          scene: newScene,
        },
      };
    }

    case act.CHANGE_UI_MODE: {
      const { uiMode } = action.payload;
      return { ...state, uiMode };
    }

    case act.SET_PROJECT_DIGESTS: {
      const { projectDigests } = action.payload;
      return {
        ...state,
        projectDigests,
      };
    }

    default:
      return state;
  }
};

export const getProjectId = (state: IAlmostAllApplicationState) => {
  return state.projectId;
};
