import { Note } from "cadius-backend";
import React from "react";

import "./preview-note.scss";

interface IProps {
  note: Note;
  selectNote: (id: string) => void;
}

export const PreviewNote: React.FC<IProps> = props => {
  const n = props.note;
  const onClick = () => {
    if (n.id) {
      props.selectNote(n.id);
    }
  };
  return (
    <div className="preview-note">
      <h3>{`Issue ${n.issue}`}</h3>
      <p>{n.message}</p>
      <button onClick={onClick} type="button">
        Click to select note
      </button>
    </div>
  );
};
