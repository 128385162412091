import { ActionCreator } from "redux";

import { log } from "cadius-stdlib";

import { FAILED_TO_INIT_APP } from "../actions";
import { CadiusDispatch, CadiusThunkAction, IAction } from "../interfaces";
import { initMaterialDB } from "./materials";
import { setprojectDigests } from "./projects";
import { setRefresher } from "./render";

/**
 * Init the application by fetching and setting all required resources.
 *
 * A possible way to initialize the application is to let each async action
 * creator dispatch its own thunk.
 *
 * With this approach the resources can be fetched/set independently, so the UI
 * can update as soon as one resource is ready.
 *
 * The downside of this approach is that this async action creator cannot know
 * which async dispatch failed, at least it cannot know it without introspecting
 * the store with `getState()`.
 */
export const initApp = (): CadiusThunkAction<void> => {
  return async (dispatch: CadiusDispatch): Promise<void> => {
    const promise = Promise.all([
      dispatch(setRefresher()),
      dispatch(initMaterialDB()),
      dispatch(setprojectDigests()),
    ]);
    try {
      await promise;
    } catch (err) {
      const info = "Failed to fetch environment maps and/or initialize PBR materials";
      const detail = err.toString();
      dispatch(failedToInitApp(info, detail));
    }
  };
};

const failedToInitApp: ActionCreator<IAction> = (
  info: string,
  detail: string
) => {
  log(info, detail);
  return { payload: { info }, type: FAILED_TO_INIT_APP };
};
