import { ActionCreator } from "redux";

import * as act from "../actions";
import { IAction } from "../interfaces";

export const toggleLast: ActionCreator<IAction> = () => {
  return { type: act.TOGGLE_LAST };
};

export const toggleLines: ActionCreator<IAction> = () => {
  return { type: act.TOGGLE_LINES };
};

export const togglePieces: ActionCreator<IAction> = () => {
  return { type: act.TOGGLE_PIECES };
};

export const toggleWireframe: ActionCreator<IAction> = () => {
  return { type: act.TOGGLE_WIREFRAME };
};

export const toggleDecorations: ActionCreator<IAction> = () => {
  return { type: act.TOGGLE_DECORATIONS };
};
