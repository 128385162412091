import { IView3DProps, View3D } from "cadius-components";
import { connect } from "react-redux";

import {
  onDragLeave,
  onDragOver,
  onKeyDown,
  onKeyUp,
  onMouseDown,
  onMouseMove,
  onMouseOut,
  onMouseOver,
  onMouseUp,
  onWheel,
} from "../actions/events";
import { subscribeRenderingRefresher, unsubscribeRenderingRefresher } from "../actions/render";
import { setCameraSize as onResize } from "../actions/ui";
import { IApplicationState } from "../interfaces";

function mapStateToProps(state: IApplicationState): IView3DProps {
  const view = state.view;
  const viewId = state.view.id;
  return { view, viewId };
}

const mapDispatchToProps: Partial<IView3DProps> = {
  onDragLeave,
  onDragOver,
  onKeyDown,
  onKeyUp,
  onMouseDown,
  onMouseMove,
  onMouseOut,
  onMouseOver,
  onMouseUp,
  onResize,
  onWheel,
  subscribeRenderingRefresher,
  unsubscribeRenderingRefresher
};

const enhance = connect(
  mapStateToProps,
  mapDispatchToProps
);

export const View3DConnected = enhance(View3D);
