import {
  CircularProgress,
  FullHeightContainer,
  IDialogProps,
  NoMatch,
  ProgressDialog,
  SvgIconRegistry,
} from "cadius-components";
import React from "react";
import { connect } from "react-redux";
import {
  HashRouter,
  Route,
  RouteComponentProps,
  Switch,
} from "react-router-dom";

import { initApp } from "../actions/init";
import { CadiusDispatch, IApplicationState } from "../interfaces";
import { ProjectNewRouteConnected } from "./ProjectNewRouteConnected";
import { ProjectsConnected } from "./ProjectsConnected";

interface IProps {
  dialog: IDialogProps;

  initApp: () => Promise<void>;
}

interface IState {
  loaded: boolean;
}

class AppImpl extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
  }

  public async componentDidMount() {
    this.setState({ loaded: false });
    await this.props.initApp();
    this.setState({ loaded: true });
  }

  public render() {
    if (!this.state || !this.state.loaded) {
      return (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </div>
      );
    }
    return (
      <div className="ui">
        <React.Fragment>
          <SvgIconRegistry />
          <FullHeightContainer>
            <HashRouter>
              <Switch>
                <Route exact={true} path="/" component={ProjectsConnected} />
                <Route
                  exact={true}
                  path="/projects/"
                  component={ProjectsConnected}
                />
                <Route
                  exact={true}
                  path="/projects/:id?"
                  component={ProjectNewRouteConnected}
                />
                {/* Catch all URLs that didn't match any route */}
                <Route render={renderNoMatch} />
              </Switch>
            </HashRouter>
          </FullHeightContainer>
          <ProgressDialog {...this.props.dialog} />
        </React.Fragment>
      </div>
    );
  }
}

function renderNoMatch(props: RouteComponentProps) {
  return <NoMatch pathname={props.location.pathname} />;
}

function mapStateToProps(state: IApplicationState) {
  const { dialog } = state;

  return {
    dialog,
  };
}

function mapDispatchToProps(dispatch: CadiusDispatch) {
  return {
    initApp: () => dispatch(initApp()),
  };
}

const enhance = connect(
  mapStateToProps,
  mapDispatchToProps
);

export const App = enhance(AppImpl);
