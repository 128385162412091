/**
 * Events-related functions and action creators.
 *
 * The functions in this module accept a DOM event and, given an existing set of
 * conditions, might perform one or more of the following:
 *
 * - create one or more Cad events
 * - discard the DOM event
 * - update some module variable that will affect subsequent function calls
 *
 * For each Cad event we create a redux action. Since there is NOT a 1-1
 * relationship between a DOM event and a Cad event, a function could accept a
 * single DOM event and dispatch multiple redux actions.
 *
 * Note: some of these functions do NOT create a Cad event, so they do NOT
 * create a redux action. Since they are still events-related functions, I think
 * it makes sense to leave them here for now.
 *
 * For the DOM events reference:
 * @see https://developer.mozilla.org/en-US/docs/Web/Events
 */
import { ScreenPosition } from "cadius-cadlib";
import { Cad, computePosition, getModifiers, IView } from "cadius-components";
import { ActionCreator } from "redux";
import { Vector2 } from "three";

import { HANDLE_CAD_EVENT, INVALID_DOM_EVENT } from "../actions";
import { CadiusDispatch, CadiusThunkAction, IAction } from "../interfaces";

// TODO: do we want to consider a click only something that occurs within the
// time window [mouseup - mousedown] ms?
export const SINGLE_CLICK_WINDOW_MS = 100;

// time window between 2 subsequent mouseup events that we wait, so we are able
// to distinguish a single "double click" from multiple "single click" events.
export const DOUBLE_CLICK_WINDOW_MS = 250;

// TODO: where to call event.stopPropagation() and/or event.preventDefault()
// and/or event.stopImmediatePropagation()? Both in invalidEvent() and in each
// DOM => Cad event action creator?

type DragObj = { x: number; y: number } | undefined;
let dragObj: DragObj;

interface IPressDatum {
  el: HTMLElement;
  position: Vector2;
  timestamp: Date;
}
export const pressMap = new Map<Cad.MouseButton, IPressDatum>();

interface IClickDatum {
  numClicks: number;
  timerId?: number;
}
const clickMap = new Map<Cad.MouseButton, IClickDatum>();
for (const btn of [
  Cad.MouseButton.LEFT,
  Cad.MouseButton.RIGHT,
  Cad.MouseButton.MIDDLE,
]) {
  clickMap.set(btn, { numClicks: 0, timerId: undefined });
}

export const onDragLeave = (event: DragEvent): IAction => {
  event.stopImmediatePropagation();

  if (!event.target) {
    return invalidEvent("This Dragleave event has no target");
  }

  if (!dragObj) {
    return invalidEvent("drag was not previously set.");
  }

  const { x, y } = dragObj;
  dragObj = undefined;

  const modifiers = getModifiers(event);
  const position = new ScreenPosition(x, y);
  const timestamp = new Date();

  const evt = new Cad.DragLeaveEvent(event.target, modifiers, position, event.button, event.buttons);

  return { payload: { evt, timestamp }, type: HANDLE_CAD_EVENT };
};

/**
 * Handle a dragover DOM event.
 *
 * Note: we handle dragover by simply setting a module variable. We don't return
 * a redux action. So this is NOT an ction creator.
 */
export const onDragOver = (event: DragEvent): IAction => {
  event.stopImmediatePropagation();

  if (!event.target) {
    return invalidEvent("This DragOver event has no target");
  }

  const position = computePosition(event);
  const { x, y } = position;
  dragObj = { x, y };

  const modifiers = getModifiers(event);
  const timestamp = new Date();

  const evt = new Cad.DragOverEvent(event.target, modifiers, position, event.button, event.buttons);

  return { payload: { evt, timestamp }, type: HANDLE_CAD_EVENT };
};

export const onFocus = (event: FocusEvent): IAction => {
  event.stopImmediatePropagation();
  return invalidEvent("The focus DOM event is currently not handled");
};

export const onKeyDown = (event: KeyboardEvent, view?: IView): IAction => {
  event.stopImmediatePropagation();

  if (!event.target) {
    return invalidEvent("This keydown event has no target");
  }

  return keyDownEvent(event, view);
};

export const onKeyUp = (event: KeyboardEvent): IAction => {
  event.stopImmediatePropagation();

  if (!event.target) {
    return invalidEvent("This keyup event has no target");
  }

  return keyUpEvent(event);
};

export const onMouseDown = (event: MouseEvent): IAction => {
  event.stopImmediatePropagation();

  if (!event.target) {
    return invalidEvent("This mousedown event has no target");
  }

  const button = getButton(event.button);
  if (button === undefined) {
    return invalidEvent("This mousedown event has no button");
  }

  return mousePressEvent(event);
};

export const onMouseMove = (event: MouseEvent): IAction => {
  event.stopImmediatePropagation();

  if (!event.target) {
    return invalidEvent("This mousemove event has no target");
  }

  return mouseMotionEvent(event);
};

export const onMouseOut = (event: MouseEvent): IAction => {
  event.stopImmediatePropagation();

  if (!event.target) {
    return invalidEvent("This mouseout event has no target");
  }

  return mouseOutEvent(event);
};

export const onMouseOver = (event: MouseEvent): IAction => {
  event.stopImmediatePropagation();

  if (!event.target) {
    return invalidEvent("This mouseover event has no target");
  }

  return mouseOverEvent(event);
};

export const onMouseUp = (event: MouseEvent): CadiusThunkAction<void> => {
  event.stopImmediatePropagation();

  return async (dispatch: CadiusDispatch): Promise<void> => {
    const button = getButton(event.button);

    let msg = "";

    if (!event.target) {
      msg = "This mouseup event has no target";
      dispatch(invalidEvent(msg));
      return;
    }

    if (!button) {
      msg = "This mouseup event has no button";
      dispatch(invalidEvent(msg));
      return;
    }

    const press = pressMap.get(button);
    if (!press) {
      msg = "pressMap was not set";
      dispatch(invalidEvent(msg));
      return;
    }
    pressMap.delete(button);

    dispatch(mouseReleaseEvent(event));

    if (event.target !== press.el) {
      msg = "mouseup target !== mousedown target (i.e. this is not a click)";
      console.warn(msg);
      return;
    }

    const msecs = (date: Date) =>
      date.getSeconds() * 1000 + date.getMilliseconds();
    const now = new Date();
    const elapsedMsecs = msecs(now) - msecs(press.timestamp);

    if (elapsedMsecs > SINGLE_CLICK_WINDOW_MS) {
      msg = `[mousedown; mouseup] > ${SINGLE_CLICK_WINDOW_MS}ms (${elapsedMsecs}ms)`;
      console.warn(msg);
    }

    const el = event.target as HTMLElement;

    const DOUBLE_CLICK_DATA_ATTR = "data-dblclick";
    if (el.getAttribute(DOUBLE_CLICK_DATA_ATTR) === null) {
      const clickDatum = clickMap.get(button);
      if (!clickDatum) {
        throw new Error("ASSERT: clickDatum was not set");
      }
      if (clickDatum.timerId !== undefined) {
        window.clearTimeout(clickDatum.timerId);
      }
      el.setAttribute(DOUBLE_CLICK_DATA_ATTR, "1");
      // We have DOUBLE_CLICK_THRESHOLD_MS to detect a double click.
      // Otherwise we detect a single click.
      clickDatum.timerId = window.setTimeout(() => {
        if (el.getAttribute(DOUBLE_CLICK_DATA_ATTR) === "1") {
          dispatch(singleClickEvent(event));
        }
        el.removeAttribute(DOUBLE_CLICK_DATA_ATTR);
      }, DOUBLE_CLICK_WINDOW_MS);
    } else {
      el.removeAttribute(DOUBLE_CLICK_DATA_ATTR);
      dispatch(doubleClickEvent(event));
    }
  };
};

export const onWheel = (event: WheelEvent): IAction => {
  event.stopImmediatePropagation();

  if (!event.target) {
    return invalidEvent("This wheel event has no target");
  }

  return wheelEvent(event);
};

/**
 * Converts the value of `button` to a bitmask-compatible value.
 *
 * The MouseEvent DOM API does not keep the same values for the property
 * `button` (the button number that was pressed when the mouse event was fired)
 * and `buttons` (the buttons depressed when the mouse event was fired).
 * https://developer.mozilla.org/en-US/docs/Web/Events/mouseup#Properties
 */
export const getButton = (() => {
  const modMap = new Map<number, Cad.MouseButton>([
    [0, Cad.MouseButton.LEFT],
    [1, Cad.MouseButton.MIDDLE],
    [2, Cad.MouseButton.RIGHT],
  ]);
  return (button: number): Cad.MouseButton | undefined => {
    return modMap.get(button);
  };
})();

/**
 * Return an action that describes the reason why the DOM event could not be
 * converted into a Cad event.
 */
export const invalidEvent: ActionCreator<IAction> = (reason?: string) => {
  return { payload: { reason }, type: INVALID_DOM_EVENT };
};

const singleClickEvent: ActionCreator<IAction> = (event: MouseEvent) => {
  const el = event.target as EventTarget;
  const modifiers = getModifiers(event);
  const position = computePosition(event);
  const button = getButton(event.button) as Cad.MouseButton;
  const timestamp = new Date();

  const evt = new Cad.MouseClickEvent(
    el,
    modifiers,
    position,
    button,
    event.buttons | button  // re-add button since this was removed during `mouseReleaseEvent`.
  );
  return { payload: { evt, timestamp }, type: HANDLE_CAD_EVENT };
};

const doubleClickEvent: ActionCreator<IAction> = (event: MouseEvent) => {
  const el = event.target as EventTarget;
  const modifiers = getModifiers(event);
  const position = computePosition(event);
  const button = getButton(event.button) as Cad.MouseButton;
  const timestamp = new Date();

  const evt = new Cad.MouseDoubleClickEvent(
    el,
    modifiers,
    position,
    button,
    event.buttons | button // re-add button since this was removed during `mouseReleaseEvent`.
  );
  return { payload: { evt, timestamp }, type: HANDLE_CAD_EVENT };
};

const mouseReleaseEvent: ActionCreator<IAction> = (event: MouseEvent) => {
  const el = event.target as EventTarget;
  const modifiers = getModifiers(event);
  const position = computePosition(event);
  const button = getButton(event.button) as Cad.MouseButton;
  const timestamp = new Date();

  const evt = new Cad.MouseReleaseEvent(
    el,
    modifiers,
    position,
    button,
    event.buttons
  );
  return { payload: { evt, timestamp }, type: HANDLE_CAD_EVENT };
};

const mouseMotionEvent: ActionCreator<IAction> = (event: MouseEvent) => {
  const el = event.target as EventTarget;
  const modifiers = getModifiers(event);
  const position = computePosition(event);
  const button = getButton(event.button) as Cad.MouseButton;
  const timestamp = new Date();

  const evt = new Cad.MouseMotionEvent(
    el,
    modifiers,
    position,
    button,
    event.buttons
  );

  return { payload: { evt, timestamp }, type: HANDLE_CAD_EVENT };
};

const mousePressEvent: ActionCreator<IAction> = (event: MouseEvent) => {
  const el = event.target as HTMLElement;
  const modifiers = getModifiers(event);
  const position = computePosition(event);
  const button = getButton(event.button) as Cad.MouseButton;
  const timestamp = new Date();

  pressMap.set(button, { el, position, timestamp });

  const evt = new Cad.MousePressEvent(
    el,
    modifiers,
    position,
    button,
    event.buttons
  );
  return { payload: { evt, timestamp }, type: HANDLE_CAD_EVENT };
};

const keyDownEvent: ActionCreator<IAction> = (event: KeyboardEvent, view?: IView) => {
  const el = event.target as HTMLElement;
  const modifiers = getModifiers(event);
  const timestamp = new Date();

  const evt = new Cad.KeyDownEvent(
    el,
    modifiers,
    event.key,
    event.code,
    event.repeat,
    view
  );
  return { payload: { evt, timestamp }, type: HANDLE_CAD_EVENT };
};

const keyUpEvent: ActionCreator<IAction> = (event: KeyboardEvent) => {
  const el = event.target as HTMLElement;
  const modifiers = getModifiers(event);
  const timestamp = new Date();

  const evt = new Cad.KeyUpEvent(el, modifiers, event.key, event.code);
  return { payload: { evt, timestamp }, type: HANDLE_CAD_EVENT };
};

const wheelEvent: ActionCreator<IAction> = (event: WheelEvent) => {
  const el = event.target as HTMLElement;
  const modifiers = getModifiers(event);
  const position = computePosition(event);
  const scale = [0.1, 1, 10][event.deltaMode];
  const offset = new Vector2(event.deltaX * scale, event.deltaY * scale);
  const timestamp = new Date();

  const evt = new Cad.MouseWheelEvent(
    el,
    modifiers,
    position,
    offset,
    event.buttons
  );
  return { payload: { evt, timestamp }, type: HANDLE_CAD_EVENT };
};

const mouseOutEvent: ActionCreator<IAction> = (event: MouseEvent) => {
  const el = event.target as HTMLElement;
  const modifiers = getModifiers(event);
  const position = computePosition(event);
  const button = getButton(event.button) as Cad.MouseButton;
  const timestamp = new Date();

  const evt = new Cad.MouseOutEvent(
    el,
    modifiers,
    position,
    button,
    event.buttons
  );
  return { payload: { evt, timestamp }, type: HANDLE_CAD_EVENT };
};

const mouseOverEvent: ActionCreator<IAction> = (event: MouseEvent) => {
  const el = event.target as HTMLElement;
  const modifiers = getModifiers(event);
  const position = computePosition(event);
  const button = getButton(event.button) as Cad.MouseButton;
  const timestamp = new Date();

  const evt = new Cad.MouseOverEvent(
    el,
    modifiers,
    position,
    button,
    event.buttons
  );
  return { payload: { evt, timestamp }, type: HANDLE_CAD_EVENT };
};
