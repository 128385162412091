import { client, Material, MaterialDB, PBRMaterial } from "cadius-backend";
import { NewMaterialFormData } from "cadius-components";
import {
  BackMiddleEdgePath,
  ConeEdgePath,
  CrossedStitch,
  DashedStitch,
  EmptyInsole,
  FeatherEdgePath,
  FrontMiddleEdgePath,
  GeodesicPolyLinePath,
  isPieceSolid,
  Last,
  ParallelDashedStitch,
  PerimeterPath,
  PieceSolid,
  PipedSeamSolid,
  SeamToolPath,
  SoleAsset,
  SurfaceCapFromGeodesic
} from "cadius-db";
import { log, zip } from "cadius-stdlib";

import * as act from "../actions";
import { CadiusDispatch, IApplicationState } from "../interfaces";
import { appError } from "./errors";
import { setProject } from "./projects";
import { registerRenderingFunction } from "./rendering-manager";

const DEFAULT_BACK_PBR_MATERIAL_ID = "bbc05c34-e506-4a14-bf1c-61f8d9aa2c6c";

export const selectMaterial = (index: number) => {
  return { type: act.SELECT_MATERIAL, payload: { index } };
};

/**
 * Removes the given material both from the local and remote material DBs.
 *
 * This action creator updates all the pieces that used the material we just removed while leaving the rest unchanged.
 *
 * @param materialId The id of the material to be removed.
 */
export const deleteMaterial = (materialId: string) => {
  const pc = client();
  return async (dispatch: CadiusDispatch, getState: () => IApplicationState) => {
    try {
      await pc.materials.delete(materialId);
      const materials = await pc.materials.fetchList();
      dispatch(setMaterialDB(materials));
    } catch (err) {
      const info = `Failed to delete material ${materialId}.`;
      const detail = err.toString();
      dispatch(appError(info, detail));
      return
    }
    const oldSolids = [...getState().project].filter((e) => {
      if (isPieceSolid(e)) {
        return e.material?.materialID === materialId;
      }
      return false;
    });

    if (oldSolids.length === 0) {
      return
    }

    const newSolids = oldSolids.map((s) => s.clone({ material: null }));
    let newProject = getState().project;
    for (const [oldEntity, newEntity] of zip(oldSolids, newSolids)) {
      newProject = newProject.replace(oldEntity, newEntity);
    }
    dispatch(setProject(getState().projectId, newProject));
  };
};

/**
 * Set the DB of all available PBR materials.
 *
 * Note: at the moment MaterialDB is **mutable**. This means that react will
 * NOT re-render if we add/remove/modify Materials in the MaterialDB
 * object. So we have to instantiate a new MaterialDB at the application startup
 * and all the times we add/remove/modify a material.
 */
export const setMaterialDB = (materials: Material[]) => {
  return (dispatch: CadiusDispatch): Promise<MaterialDB> => {
    const materialDB = new MaterialDB();
    materialDB.materials = materials;
    dispatch({ type: act.SET_MATERIAL_DB, payload: { materialDB } });
    return Promise.resolve(materialDB);
  };
};

export const initMaterialDB = () => {
  const pc = client();
  return async (dispatch: CadiusDispatch): Promise<void> => {
    try {
      dispatch({ payload: { isFetching: true }, type: act.SET_MATERIALS_FETCHING });
      const materials = await pc.materials.fetchList();
      const materialDB = await dispatch(setMaterialDB(materials));

      // Registering rendering function to the RenderManager
      dispatch(registerRenderingFunction(Last.name, Last.typeInfo, "Solid", materialDB));

      dispatch(registerRenderingFunction(SoleAsset.name, SoleAsset.typeInfo, "Asset"));
      dispatch(registerRenderingFunction(DashedStitch.name, DashedStitch.typeInfo, "Asset"));
      dispatch(registerRenderingFunction(ParallelDashedStitch.name, ParallelDashedStitch.typeInfo, "Asset"));
      dispatch(registerRenderingFunction(CrossedStitch.name, CrossedStitch.typeInfo, "Asset"));

      dispatch(registerRenderingFunction(BackMiddleEdgePath.name, BackMiddleEdgePath.typeInfo, "Path"));
      dispatch(registerRenderingFunction(ConeEdgePath.name, ConeEdgePath.typeInfo, "Path"));
      dispatch(registerRenderingFunction(FeatherEdgePath.name, FeatherEdgePath.typeInfo, "Path"));
      dispatch(registerRenderingFunction(FrontMiddleEdgePath.name, FrontMiddleEdgePath.typeInfo, "Path"));
      dispatch(registerRenderingFunction(GeodesicPolyLinePath.name, GeodesicPolyLinePath.typeInfo, "Path"));
      dispatch(registerRenderingFunction(SeamToolPath.name, SeamToolPath.typeInfo, "Path"));

      dispatch(registerRenderingFunction(EmptyInsole.name, EmptyInsole.typeInfo, "Solid"));
      dispatch(registerRenderingFunction(SurfaceCapFromGeodesic.name, SurfaceCapFromGeodesic.typeInfo, "SurfaceCap"));

      dispatch(registerRenderingFunction(PerimeterPath.name, PerimeterPath.typeInfo, "Path"));

      dispatch(registerRenderingFunction(PieceSolid.name, PieceSolid.typeInfo, "Solid", materialDB));
      dispatch(registerRenderingFunction(PipedSeamSolid.name, PieceSolid.typeInfo, "Solid", materialDB));
    } catch (err) {
      const info = `Failed to init material DB.`;
      const detail = err.toString();
      dispatch(appError(info, detail));
    } finally {
      dispatch({ payload: { isFetching: false }, type: act.SET_MATERIALS_FETCHING });
    }
  };
};

/**
 * Get the data from the form, send normalMap, metalnessMap, etc to the API if
 * available, create the definition of a new material.
 *
 * @see https://gitlab.comelz.com/dvd/cadius/issues/345
 */
export const onNewMaterial = (data: NewMaterialFormData) => {
  const pc = client();

  return async (dispatch: CadiusDispatch): Promise<void> => {
    const back_pbr_material_id = DEFAULT_BACK_PBR_MATERIAL_ID;
    const side_pbr_material_id = back_pbr_material_id;

    const metalness = data.metalnessMap ? 1 : 0;
    const roughness = data.roughnessMap ? 1 : 0.3;

    const pbrMaterialName = `${data.materialName} - PBR`;
    let pbrMaterial: PBRMaterial;
    try {
      pbrMaterial = await pc.pbr_materials.create({
        diffuse: [1, 1, 1, 1],
        metalness,
        name: pbrMaterialName,
        reflectivity: 0.5,
        roughness,
      });
    } catch (err) {
      const info = `Failed to create PBR material ${data.materialName}.`;
      const detail = err.toString();
      dispatch(appError(info, detail));
      // I don't think there is anything we can do if the creation of a new PBR
      // material fails, so we return.
      return;
    }

    // TODO: what to do in ONE of these changeMap promise is rejected?

    let buffer = await new Response(data.normalMap).arrayBuffer();
    await pc.pbr_materials.changeNormalMap(pbrMaterial.id, buffer);

    buffer = await new Response(data.diffuseMap).arrayBuffer();
    await pc.pbr_materials.changeDiffuseMap(pbrMaterial.id, buffer);

    if (data.metalnessMap) {
      buffer = await new Response(data.metalnessMap).arrayBuffer();
      await pc.pbr_materials.changeMetalnessMap(pbrMaterial.id, buffer);
    }

    if (data.roughnessMap) {
      buffer = await new Response(data.roughnessMap).arrayBuffer();
      await pc.pbr_materials.changeRoughnessMap(pbrMaterial.id, buffer);
    }

    let material: Material;
    try {
      material = await pc.materials.create({
        back_pbr_material_id,
        front_pbr_material_id: pbrMaterial.id,
        name: data.materialName,
        side_pbr_material_id,
        thickness: parseFloat(data.thickness),
      });
    } catch (err) {
      const info = `Failed to create material ${data.materialName}.`;
      const detail = err.toString();
      dispatch(appError(info, detail));
      // I don't think there is anything we can do if the creation of a new
      // material fails, so we return.
      return;
    }

    const IMG_PREVIEW_SIZE = 256;
    const canvas = document.createElement("canvas");
    canvas.width = IMG_PREVIEW_SIZE;
    canvas.height = IMG_PREVIEW_SIZE;
    const ctx = canvas.getContext("2d");

    await renderImgInCanvas(ctx!, data.diffuseMap, IMG_PREVIEW_SIZE);

    canvas.toBlob(async (blob) => {
      buffer = await new Response(blob).arrayBuffer();

      try {
        await Promise.all([
          pc.pbr_materials.changePreview(pbrMaterial.id, buffer),
          pc.materials.changePreview(material.id, buffer),
        ]);
      } catch (err) {
        log(`Could not change preview image for ${pbrMaterial.name} or ${material.name}`);
      }

      // The backend now has a new material with the correct preview image, but
      // the frontend still has the old list of materials. Let's update it.
      try {
        dispatch({ payload: { isFetching: true }, type: act.SET_MATERIALS_FETCHING });
        const materials = await pc.materials.fetchList();
        dispatch(setMaterialDB(materials));
      } catch (err) {
        log(`Could not fetch the updated list of materials`);
      } finally {
        dispatch({ payload: { isFetching: false }, type: act.SET_MATERIALS_FETCHING });
      }
    }, "image/png");
  };
};

/**
 * Draw in a 2D canvas rendering context, the content of the blob passed in.
 * The rendered image will be size x size.
 */
const renderImgInCanvas = (ctx: CanvasRenderingContext2D, blob: Blob, size: number) => {
  const img = new Image();
  return new Promise<void>((resolve, reject) => {
    img.onload = () => {
      ctx.drawImage(img, 0, 0, size, size);
      URL.revokeObjectURL(img.src);
      resolve();
    };

    img.onerror = () => {
      reject(`Image ${img.name} failed to load.`);
    };

    img.src = URL.createObjectURL(blob);
  });
};
