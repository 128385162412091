import { connect } from "react-redux";

import { deleteProject, setprojectDigests } from "../actions/projects";
import { Projects } from "../components/Projects";
import { CadiusDispatch, IApplicationState } from "../interfaces";

function mapStateToProps(state: IApplicationState) {
  const projectDigests = state.projectDigests;
  const isLoading = projectDigests.length > 0 ? false : true;
  const projectId = state.projectId;

  return {
    isLoading,
    projectDigests,
    projectId,
  };
}

const mapDispatchToProps = (dispatch: CadiusDispatch) => {
  return {
    deleteProject: async (prjId: string) => {
      await dispatch(deleteProject(prjId));
      dispatch(setprojectDigests());
    },
    setprojectDigests: () => dispatch(setprojectDigests()),
  };
};

const enhance = connect(
  mapStateToProps,
  mapDispatchToProps
);

export const ProjectsConnected = enhance(Projects);
