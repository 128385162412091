import { APP_ERROR } from "../actions";

/**
 * Action creator used to notify the app of an operational error.
 *
 * @param info User-friendly error message
 * @param detail detailed error message (e.g. for logging)
 */
export const appError = (info: string, detail: string) => {
  return { payload: { detail, info }, type: APP_ERROR };
};
