import { ProjectDigest } from "cadius-backend";
import { CircularProgress, IconName, makeIcon } from "cadius-components";
import React, { useEffect } from "react";
import { Link, Redirect } from "react-router-dom";

import { NewProjectFormConnected } from "../containers/NewProjectFormConnected";

interface IProps {
  /**
   * If `true`, the projects are not yet available (e.g. they are being fetched
   * from an API).
   */
  isLoading: boolean;

  /**
   * The list of project digests.
   */
  projectDigests: ProjectDigest[];

  /**
   * The projectId of the selected project.
   *
   * Note that the projectId can either be set by the user, who clicks the
   * project link in the UI, or by some background operation (e.g. as soon as
   * the .cal file upload is completed, we might want to redirect to the project
   * page).
   */
  projectId?: string;

  deleteProject: (projId: string) => void;

  /**
   * Callback invoked as soon as this component mounts (e.g. fetch the list of
   * project digests from an API).
   */
  setprojectDigests: () => Promise<void>;
}

export const Projects: React.FC<IProps> = (props) => {
  const { isLoading, projectDigests, projectId, deleteProject, setprojectDigests } = props;

  useEffect(() => {
    setprojectDigests();
  }, []);

  const renderProjectDigest = (pd: ProjectDigest) => {
    const delPrj = () => {
      deleteProject(pd.id);
    };

    return (
      <li key={pd.id}>
        <span>
          <span className="svg-icon-container" onClick={delPrj}>
            {/* <Link onClick={delPrj} to={`.`}>delete</Link> */}
            {makeIcon(IconName.Delete)}
          </span>
          <Link key={pd.id} to={`/projects/${pd.id}`}>
            {pd.name}
          </Link>
        </span>
      </li>
    );
  };

  return projectId ? (
    <Redirect to={`/projects/${projectId}`} />
  ) : (
      <div>
        <div style={{ backgroundColor: "orange" }}>
          {isLoading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </div>
          ) : (
              <ol>{projectDigests.map(renderProjectDigest)}</ol>
            )}
        </div>
        <div style={{ backgroundColor: "orange", width: "30rem" }}>
          <NewProjectFormConnected />
        </div>
      </div>
    );
};
