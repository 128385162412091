import { SavedCamera } from "cadius-cadlib";
import {
  MessageResource,
  Thread,
  ThreadUser,
} from "cadius-components";
import React from "react";

interface Comment {
  id?: string;
  message: string;
  timestamp: string;
  username: string;
}

interface IProps {
  archived?: boolean;
  author: ThreadUser;
  camera?: SavedCamera;
  categoryColor: string;
  content: string;
  id?: string;
  comments: Comment[];
  onArchive?: () => void;
  onDelete: () => void;
  onDeleteComment: (commentId: string) => void;
  onEditComment: (commentId: string, editedComment: string) => void;
  onNewComment: (comment: string) => void;
  onUnarchive?: () => void;
  selectNote: (id: string) => void;
  timestamp: string;
}

/**
 * This component renders a discussion about a particular issue pertaining a
 * given category. The discussion is started by an `author`, contains the first
 * message that started the discussion, and might contain `comments` made by the
 * same author or other users.
 * The note can be archived, unarchived, deleted by the user who has the
 * required privileges.
 * Any given comment in the note can be edited or deleted by the user who has
 * the required privileges.
 */
export const Note: React.FC<IProps> = (props) => {
  const {
    archived,
    author,
    categoryColor,
    comments,
    content,
    id,
    onArchive,
    onDelete,
    onDeleteComment,
    onEditComment,
    onNewComment,
    onUnarchive,
    selectNote,
    timestamp,
  } = props;

  const messages = comments.map((c: Comment, i: number) => {
    const m = c.id ? c : { ...c, id: `tmp-comment-id-${i}` };
    return m as MessageResource;
  });

  const onClick = () => {
    if (id) {
      selectNote(id);
    }
  };

  return (
    <div className="box" onClick={onClick} style={{ cursor: "pointer" }}>
      <Thread
        archived={archived}
        author={author}
        categoryColor={categoryColor}
        highlighted={false}
        id={id || "tmp-note-id"}
        messages={messages}
        onArchive={onArchive}
        onDelete={onDelete}
        onDeleteMessage={onDeleteComment}
        onEditMessage={onEditComment}
        onNewMessage={onNewComment}
        onUnarchive={onUnarchive}
        threadMessage={content}
        timestamp={timestamp}
      />
    </div>
  );
};
