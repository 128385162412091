import { Popover, ViewContainer } from "cadius-components";
import React from "react";

import { View3DConnected } from "../containers/View3DConnected";
import {
  IPreviewNotePopover,
  IWriteNotePopover,
  NoteIssue,
} from "../interfaces";
import { NewNote } from "./NewNote";
import { PreviewNote } from "./PreviewNote";

interface IProps {
  /**
   * Create a new note. All notes are about a given `issue` and contain some
   * text `content`.
   */
  onCreateNewNote: (content: string, issue: NoteIssue) => void;

  /**
   * Close the popover with the note currently being edited. Dismissing the
   * current note implies discarding the note.
   */
  dismissCurrentNote: () => void;

  /**
   * The position of the popover used to create new notes. It's only available
   * when a note is currently being written, otherwise it's `undefined`.
   */
  previewNotePopover?: IPreviewNotePopover;

  /**
   * Picasso style project ID.
   */
  projectId?: string;

  selectNote: (id: string) => void;
  writeNotePopover?: IWriteNotePopover;
}

/**
 * This component renders a store-connected View3D component (i.e. a View3D
 * connected to a redux store) and a popover used to create annotations about
 * the project being displayed on the screen.
 */
export const ProjectView: React.FC<IProps> = props => {
  const { dismissCurrentNote, onCreateNewNote, projectId, selectNote } = props;
  const pnp = props.previewNotePopover;
  const wnp = props.writeNotePopover;

  return (
    <div style={{ position: "relative" }}>
      <ViewContainer>
        <View3DConnected />
      </ViewContainer>
      {wnp && (
        <Popover x={`${wnp.pos.x}px`} y={`${wnp.pos.y}px`}>
          {projectId ? (
            <NewNote
              onCreateNewNote={onCreateNewNote}
              onDismissCurrentNote={dismissCurrentNote}
              projectId={projectId}
            />
          ) : (
            "No project ID"
          )}
        </Popover>
      )}
      {pnp && (
        <Popover x={`${pnp.pos.x}px`} y={`${pnp.pos.y}px`}>
          <PreviewNote note={pnp.note} selectNote={selectNote} />
        </Popover>
      )}
    </div>
  );
};
