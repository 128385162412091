import { Material } from "cadius-backend";
import { Materials } from "cadius-components";
import { connect } from "react-redux";

import { deleteMaterial, selectMaterial } from "../actions/materials";
import { IApplicationState } from "../interfaces";
import { getIndexSelectedMaterial } from "../reducers/materials";

function mapStateToProps(state: IApplicationState) {
  const isFetching = state.isFetching;
  const indexSelectedMaterial = getIndexSelectedMaterial(state);
  const projectMaterials = state.materialDB ? state.materialDB.materials : new Array<Material>();

  // TODO: this always creates new items, causing a re-render every time.
  const items = projectMaterials.map((obj, i) => {
    return {
      key: `material-${i}`,
      obj,
    };
  });

  return {
    indexSelectedMaterial,
    isFetching,
    items,
  };
}

// mapDispatchToProps shorthand syntax
// https://react-redux.js.org/using-react-redux/connect-mapdispatch#two-forms-of-mapdispatchtoprops
const mapDispatchToProps = {
  deleteMaterial,
  selectMaterial,
};

const enhance = connect(
  mapStateToProps,
  mapDispatchToProps
);

/**
 * A `Materials` component connected to a redux store, so all of its props are
 * either taken from the application global state, or perform actions on the
 * redux store.
 */
export const MaterialsConnected = enhance(Materials);
