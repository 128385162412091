import { ICON, svgIcon, TabCluster } from "cadius-components";
import React from "react";

import { UiMode } from "../interfaces";

interface IProps {
  changeUiMode: (uiMode: UiMode) => void;
  uiMode: UiMode;
}

const AnnotationsTabLabel = "Annotations";
const MaterialsTabLabel = "Materials";

export const Tabs: React.FC<IProps> = props => {
  const onTabSelected = (event: React.MouseEvent, label: string) => {
    switch (label) {
      case AnnotationsTabLabel: {
        props.changeUiMode(UiMode.ANNOTATE);
        break;
      }
      case MaterialsTabLabel: {
        props.changeUiMode(UiMode.CHANGE_MATERIAL);
        break;
      }
      default:
        console.warn(`Tab label ${label} does not dispatch any action`);
    }
  };

  return (
    <TabCluster onTabSelected={onTabSelected}>
      <button
        className="tab"
        data-tabname={MaterialsTabLabel}
        role="tab"
        tabIndex={0}
        style={{ fontSize: "1.5rem" }}
      >
        {svgIcon(ICON.MATERIALS)}
        <span>materials</span>
      </button>
      <button
        className="tab"
        data-tabname={AnnotationsTabLabel}
        role="tab"
        tabIndex={0}
        style={{ fontSize: "1.5rem" }}
      >
        {svgIcon(ICON.COMMENTS)}
        <span>notes</span>
      </button>
    </TabCluster>
  );
};
