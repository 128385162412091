import { MaterialDB } from "cadius-backend";
import {
  CameraOrientation,
  Render3DContext,
  Render3DContextFilters,
  RenderManager,
} from "cadius-cadlib";
import {
  CadiusSceneWithRenderManager,
  defaultFilters,
  ReactiveCadiusSceneWithRenderManager,
  SymmetryPlane,
} from "cadius-components";
import { Entity, Last } from "cadius-db";
import { reducers as debugReducers } from "cadius-debug";

// TODO: this function is also in cadius-forms. Move it where it can be shared
// (maybe cadius-components?)
export const makeScene = (
  renderManager: RenderManager,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  materialDB?: MaterialDB,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  last?: Last,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  planeSource?: SymmetryPlane,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  orientation: CameraOrientation = CameraOrientation.perspective,
  filters: Render3DContextFilters = defaultFilters(),
): ReactiveCadiusSceneWithRenderManager => {
  const entities: Entity[] = [];



  const ctx: Render3DContext = {
    debug: debugReducers.initialState(),
    envMaps: null,
    filters,
    refresh: () => Promise.resolve(),
  };

  const rcs = new ReactiveCadiusSceneWithRenderManager(new CadiusSceneWithRenderManager(renderManager));
  return rcs.update(entities, ctx);
};
