import { Comment, Note } from "cadius-backend";
import { SavedCamera } from "cadius-cadlib";
import { PunctualObjectRStarTree } from "cadius-geo";
import { log } from "cadius-stdlib";
import { Reducer } from "redux";
import { Vector3 } from "three";

import * as act from "../actions";
// import { areNotesEqual, IAction, IComment, IIndexedNote, INote, INotesState, NoteIssue } from "../interfaces";
import { areNotesEqual, IAction, IIndexedNote, INotesState, NoteIssue } from "../interfaces";

export const reducer: Reducer<INotesState, IAction> = (state = initialState(), action): INotesState => {
  switch (action.type) {

    case act.ADD_NOTE: {
      const { camera, id, issue, message, position, username } = action.payload as {
        camera: SavedCamera,
        id: string,
        issue: NoteIssue,
        message: string,
        position: Vector3,
        username: string,
      };

      const issue_position = position.toArray() as [number, number, number];

      const note = {
        archived: false,
        camera,
        comments: [],
        id,
        issue,
        issue_position,
        message,
        // timestamp: "yesterday",
        username,
      };

      const indexedNote: IIndexedNote = {
        id,
        position,
      };
      const indexedNotes = [...state.indexedNotes, indexedNote];
      state.noteGeoIndex.add(indexedNote);
      log(`added note with id='${id}' into noteGeoIndex`);

      const notes = [...state.notes, note] as Note[];

      return {
        ...state,
        indexedNotes,
        notes,
        writeNotePopover: undefined,
      };
    }

    case act.REPLACE_NOTE_ID: {
      const { backendId, localId } = action.payload as { backendId: string, localId: string };
      const indexedNotes = state.indexedNotes.map(
        (n): IIndexedNote => {
          if (n.id === localId) {
            state.noteGeoIndex.remove(n);
            n = { ...n, id: backendId };
            state.noteGeoIndex.add(n);
            log(`replaced note {id='${localId}'} with note {id='${backendId}'} into noteGeoIndex`);
            return n;
          }
          return n;
        }
      );
      const notes = state.notes.map((n): Note => n.id === localId ? { ...n, id: backendId } : n);
      return { ...state, indexedNotes, notes };
    }

    case act.REMOVE_NOTE: {
      const { id } = action.payload;
      const indexedNotes = state.indexedNotes.filter(
        (n): boolean => {
          if (n.id === id) {
            state.noteGeoIndex.remove(n);
            log(`removed note with id='${id}' from noteGeoIndex`);
            return false;
          }
          return true;
        }
      );
      const notes = state.notes.filter((n: Note) => n.id !== id);
      return { ...state, indexedNotes, notes };
    }

    case act.ADD_COMMENT_TO_NOTE: {
      const { commentId, message, noteId } = action.payload;
      const notes = state.notes.map((n: Note) => {
        const comments = n.id === noteId ? [...n.comments, { id: commentId, message }] : n.comments;
        return { ...n, comments };
      });
      return { ...state, notes };
    }

    case act.REPLACE_COMMENT_ID: {
      const { backendId, localId, noteId } = action.payload;
      const replaceCommentIdIfLocal = (n: Note) => {
        const replaceIdIfLocal = (c: Comment) => {
          return c.id === localId ? { ...c, id: backendId } : c;
        };
        const comments = n.comments.map(replaceIdIfLocal);
        return n.id === noteId ? { ...n, comments } : n;
      };
      const notes = state.notes.map(replaceCommentIdIfLocal);
      return { ...state, notes };
    }

    case act.REMOVE_COMMENT_FROM_NOTE: {
      const { commentId, noteId } = action.payload;
      const notes = state.notes.map((n: Note) => {
        const fn = (c: Comment) => c.id !== commentId;
        const comments = n.id === noteId ? n.comments.filter(fn) : n.comments;
        return { ...n, comments };
      });
      return { ...state, notes };
    }

    case act.EDIT_COMMENT_IN_NOTE: {
      const { commentId, editedComment, noteId } = action.payload;
      return {
        ...state,
        notes: state.notes.map((n) => {
          return {
            ...n,
            comments: n.id === noteId ?
              n.comments.map((c) => c.id === commentId ? { ...c, message: editedComment } : c) :
              n.comments,
          };
        }),
      };
    }

    case act.ARCHIVE_NOTE: {
      const { id } = action.payload;
      const notes = state.notes.map((n: Note) => {
        return n.id === id ? { ...n, archived: true } : n;
      });
      return { ...state, notes };
    }

    case act.UNARCHIVE_NOTE: {
      const { id } = action.payload;
      const notes = state.notes.map((n: Note) => {
        return n.id === id ? { ...n, archived: false } : n;
      });
      return { ...state, notes };
    }

    case act.START_POLLING_NOTES: {
      const { intervalTimer } = action.payload;
      return { ...state, intervalTimer };
    }

    case act.STOP_POLLING_NOTES: {
      clearInterval(state.intervalTimer);
      return { ...state, intervalTimer: undefined };
    }

    case act.DISMISS_CURRENT_NOTE: {
      return { ...state, writeNotePopover: undefined };
    }

    case act.START_WRITING_NOTE: {
      const { p, pos } = action.payload;
      return { ...state, writeNotePopover: { point: p, pos } };
    }

    case act.OPEN_POPUP_NOTE: {
      const { note, pos } = action.payload;
      return { ...state, previewNotePopover: { note, pos } };
    }

    case act.CLOSE_POPUP_NOTE: {
      return { ...state, previewNotePopover: undefined };
    }

    case act.SELECT_NOTE: {
      const { id } = action.payload;

      return {
        ...state,
        previewNotePopover: undefined,
        selectedNoteId: id,
      };
    }

    case act.SYNC_NOTES: {
      // TODO: concat notes and keep only the one in the state if there is a
      // note with the same id coming from the API.
      const { notesFromAPI } = action.payload;
      const { newIndexedNotes, newNotes, updatedNotes } = reconcileNotes(notesFromAPI, state);
      for (const n of newIndexedNotes) {
        state.noteGeoIndex.add(n);
      }

      const notes = state.notes.map((n: Note): Note => updatedNotes.get(n.id) ?? n);
      notes.push(...newNotes);

      return {
        ...state,
        indexedNotes: [...state.indexedNotes, ...newIndexedNotes],
        notes,
      };
    }

    case act.SET_NOTES_FETCHING: {
      const { isFetching } = action.payload;
      return {
        ...state,
        isFetching,
      };
    }

    default:
      return state;
  }
};

export const initialState = (): INotesState => {
  return {
    indexedNotes: [],
    isFetching: false,
    noteGeoIndex: new PunctualObjectRStarTree<Vector3, IIndexedNote>(Vector3, true, 4),
    notes: [],
  };
};

interface ReconciledNotes {
  newNotes: Note[];
  newIndexedNotes: IIndexedNote[];
  updatedNotes: Map<string, Note>;
}

function reconcileNotes(notesFromAPI: Note[], state: INotesState): ReconciledNotes {
  const alreadyPresent = new Map<string, Note>();
  state.notes.forEach((n: Note) => {
    alreadyPresent.set(n.id, n);
  });

  const newNotes = new Array<Note>();
  const newIndexedNotes = new Array<IIndexedNote>();
  const updatedNotes = new Map<string, Note>();

  for (const remoteNote of notesFromAPI) {
    const currentNote = alreadyPresent.get(remoteNote.id);
    if (!currentNote) {
      newNotes.push(remoteNote);
      const [x, y, z] = remoteNote.issue_position;
      const position = new Vector3(x, y, z);
      newIndexedNotes.push({ id: remoteNote.id, position });
    } else if (!areNotesEqual(currentNote, remoteNote)) {
      updatedNotes.set(remoteNote.id, remoteNote);
    }
  }

  return {
    newIndexedNotes,
    newNotes,
    updatedNotes,
  };
}
