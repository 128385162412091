import { ActionCreator } from "redux";
import {
  CubeReflectionMapping,
  CubeRefractionMapping,
  CubeTexture,
  CubeTextureLoader,
} from "three";

import { EnvMaps } from "cadius-cadlib";
import {
  DEFAULT_MAX_TEXTURE_SIZE,
  EnvironmentMapSpec,
  MaxTextureSize,
  requests as req,
} from "cadius-components";
import { log } from "cadius-stdlib";

import { SET_ENV_MAP, SET_ENV_MAP_SPECS } from "../actions";
import { CadiusDispatch, CadiusThunkAction, IAction } from "../interfaces";
import { appError } from "./errors";

/**
 * Fetch a single environment map from the API server.
 *
 * This function loads the 6 textures from the URIs stored in
 * environmentMap.cube, puts them into two CubeTextures, one as the reflective,
 * the other as the refractive environment map used by mesh with a material
 * supporting it.
 *
 * This function loads 6 textures asynchronously and creates both a reflective
 * environment map, and a refractive environment map. Then it dispatches an
 * action to set the environment map (reflective and refractive) in the store.
 *
 * @param {EnvironmentMapSpec} environmentMap A description of the environment
 * map, together with the URIs of the textures to load. If it is undefined, it
 * means to substitute the current envMap with nothing.
 * @param {(MaxTextureSize | null | undefined)} [maxSize=DEFAULT_MAX_TEXTURE_SIZE]
 * @returns {CadiusThunkAction<void>}
 */
export const fetchEnvironmentMap = (
  environmentMap: EnvironmentMapSpec,
  maxSize: MaxTextureSize | null | undefined = DEFAULT_MAX_TEXTURE_SIZE
): CadiusThunkAction<void> => {
  const cubeTextureLoader = new CubeTextureLoader();

  /**
   * Returns a new refractive CubeTexture built starting from a reflective one
   * by swapping the 6 faces: front with back, left with right and top with
   * bottom.
   * @param {CubeTexture} t The CubeTexture to clone.
   * @returns {CubeTexture} A clone of t, with the faces swapped.
   */
  function makeRefractive(t: CubeTexture): CubeTexture {
    const r = t.clone() as CubeTexture;
    // switch front with back, left with right and bottom with top
    r.image = r.images = [
      t.images[1],
      t.images[0],
      t.images[3],
      t.images[2],
      t.images[5],
      t.images[4],
    ];
    r.mapping = CubeRefractionMapping;
    return r;
  }

  return async (dispatch: CadiusDispatch): Promise<void> => {
    let urls = environmentMap.cube;
    if (maxSize !== null) {
      urls = urls.map(u => u + `?maxSize=${maxSize}`);
    }

    const promise: Promise<EnvMaps> = new Promise((resolve, reject) => {
      const onLoad = (t: CubeTexture) => {
        t.mapping = CubeReflectionMapping;
        resolve({
          reflective: t,
          refractive: makeRefractive(t),
        });
      };
      const onProgress = undefined;
      const onError = reject;

      cubeTextureLoader.load(urls, onLoad, onProgress, onError);
    });

    try {
      const envmap = await promise;
      dispatch(setEnvMap(envmap));
    } catch (err) {
      const info = "Failed to fetch environment map";
      const detail = err.toString();
      // TODO: we have to think about a UX solution for this scenario: what do
      // we show the user? The default alert is just a temporary solution.
      alert(info);
      dispatch(appError(info, detail));
    }
  };
};

/**
 * Fetch a list of environment maps from the API server.
 */
export const fetchEnvironmentMaps = (): CadiusThunkAction<void> => {
  return async (dispatch: CadiusDispatch): Promise<void> => {
    try {
      const envs = await thunkEnvMaps();
      dispatch(setEnvMapList(envs));
    } catch (err) {
      const info = "Failed to fetch environment maps";
      const detail = err.toString();
      dispatch(appError(info, detail));
    }
  };
};

const setEnvMap: ActionCreator<IAction> = (envmap: EnvMaps | null) => {
  log("Set environment map");
  return { payload: { envmap }, type: SET_ENV_MAP };
};

export const setEnvMapList: ActionCreator<IAction> = (
  specs: EnvironmentMapSpec[]
) => {
  log("Set environment maps:", specs.map(s => s.name));
  return { payload: { specs }, type: SET_ENV_MAP_SPECS };
};

const thunkEnvMaps = () => {
  return req.get("env_maps/") as Promise<EnvironmentMapSpec[]>;
};
