const LOCAL_ID_PREFIX = "local-id-";

export function isIdLocal(id: string) {
  return id.includes(LOCAL_ID_PREFIX);
}

let _counter = -1;

/**
 * Client-side generated ID.
 *
 * Some entities require an ID to be uniquely identified (e.g. notes, comments).
 * Tipically, this ID is some kind of UUID assigned by the backend. However,
 * since this server-side generated ID can't be immediately available in the
 * application (i.e. the server needs to receive the network request, process
 * it, come up with a response) we have to use a temporariry, client-side
 * generated ID.
 */
export function newLocalId() {
  _counter = _counter + 1;
  return `${LOCAL_ID_PREFIX}${_counter}`;
}
