import { Comment, MaterialDB, Note, ProjectDigest } from "cadius-backend";
import {
  EnvMaps,
  IndexedSolidMeshScene,
  Render3DContextFilters,
  RenderManager,
  ScreenPosition
} from "cadius-cadlib";
import {
  CadiusDispatch as CommonCadiusDispatch,
  CadiusThunkAction as CommonCadiusThunkAction,
  EnvironmentMapSpec,
  IAction as CommonCadiusAction,
  IDialogProps,
  InteractorStack,
  IView,
  RenderingRefreshCallback,
} from "cadius-components";
import { Project } from "cadius-db";
import { reducers as debugReducers } from "cadius-debug";
import { PunctualObjectRStarTree } from "cadius-geo";
import { Vector3 } from "three";

export enum UiMode {
  ANNOTATE = "annotate",
  CHANGE_MATERIAL = "change-material",
}

export type IAction = CommonCadiusAction<any>;

export type CadiusDispatch = CommonCadiusDispatch<IApplicationState>;

export type CadiusThunkAction<P> = CommonCadiusThunkAction<P, IApplicationState>;

export interface IMaterialsState {
  /**
   * Index of the currently selected material, out of all the materials
   * available in the current `Project`.
   */
  readonly indexSelectedMaterial?: number;

  /**
   * Whether the materials are currently being fetched from the backend or not.
   */
  isFetching: boolean;
}

export interface IAlmostAllApplicationState {
  /**
   * Callback to pass to the `CadiusScene.update()` in order to trigger a
   * refresh of the view. It'll probably need the dispatch thus it can't be
   * created directly from the reducer.
   */
  readonly refresh: () => Promise<void>;

  /**
   * Set of callbacks to call when refreshing the WebGL rendering is needed
   * (and no state change is possible nor convenient). Owners of any view
   * will register their callback through `subscribeRenderingRefreshers()`
   * which will be added to this set.
   * @type {Set<RenderingRefreshCallback>}
   * @memberof IAlmostAllApplicationState
   */
  readonly subscribedRefreshers: Set<RenderingRefreshCallback>;

  readonly debug: debugReducers.IDebugState;

  /**
   * Version of the DebugLines object used by cadius-visual-debug.
   */
  readonly debugLinesVersion: number;

  /**
   * Dialog to give the user some background information on the ongoing
   * operation (e.g. fetching data, building geometry).
   */
  readonly dialog: IDialogProps;

  /**
   * Environment maps to use for reflective/refractive materials.
   */
  readonly envMap: EnvMaps | null;

  /**
   * Specs that describe and identify the environment maps used.
   */
  readonly envMapsSpecs: EnvironmentMapSpec[] | null;

  /**
   * Binary flags to modify how several objects are rendered in the 3D scene.
   */
  readonly filters: Render3DContextFilters;

  /**
   * Number that represents the ID value of the timer that is set.
   *
   * If the timer was set with `window.setInterval()`, we can use this value
   * with the `clearInterval()` method to cancel the timer.
   */
  readonly intervalId: number;

  /**
   * Timestamp (as number of milliseconds elapsed since January 1, 1970) of the
   * last Cad event that was handled.
   */
  readonly lastEvent: number;

  /**
   * Container of materials.
   */
  readonly materialDB?: MaterialDB;

  /**
   * The position of a popover in screen coordinates. It's `undefined` if there
   * is no popover.
   */
  readonly popoverPosition?: ScreenPosition;

  /**
   * Project to use throughout the application. An instance of Project can be
   * created by deserializing an API response, calling `newEmptyProject()`, etc.
   */
  readonly project: Project;

  /**
   * The current style project's name.
   */
  readonly projectName?: string;

  /**
   * The name of the remeshing project the current style is based on.
   */
  readonly remeshingProjectName?: string;

  /**
   * Style projects coming from the API.
   */
  readonly projectDigests: ProjectDigest[];

  /**
   * Identifier for the current Project instance.
   *
   * TODO: at the moment a `.cal` to `Project` conversion does **NOT** generate
   * a `projectId`, so fetching a project from the API, then converting a `.cal`
   * into a new `Project`, leaves the application in an inconsistent state.
   */
  readonly projectId: string;

  /**
   * Stateful object that holds all the solid entities available in the
   * application, indexed for faster access. The solid entities should be
   * re-indexed when the project changes.
   */
  readonly solidScene: IndexedSolidMeshScene;

  /**
   * List of renderable primitives in a 3D scene.
   */
  readonly renderManager: RenderManager;

  /**
   * The UI main mode.
   * In the "annotate" mode the user can add a new note; in the
   * "change-material" mode he can work with the pieces material.
   */
  readonly uiMode: UiMode;

  // TODO: should this be like IRemeshModelState in cadius-forms (i.e. with sources)?
  readonly view: IView;
}

export enum CategoryColor {
  "geometry" = "blue",
  "material" = "red",
}

export type NoteIssue = keyof typeof CategoryColor;

export function areCommentsEqual(a: Comment, b: Comment): boolean {
  return a.id === b.id &&
    a.message === b.message &&
    a.username === b.username &&
    a.timestamp === b.timestamp;
}

export interface IIndexedNote {
  /**
   * Unique identifier for the note.
   * Since a note is immediately saved in the application state, we initially
   * use a temporary client-side-generated ID. Then we replace it with a
   * server-side-generated ID.
   */
  id: string;

  /**
   * The position of the note in the 3D scene.
   */
  position: Vector3;
}

export function areNotesEqual(a: Note, b: Note): boolean {
  if (
    a.id !== b.id ||
    a.issue !== b.issue ||
    a.issue_position[0] !== b.issue_position[0] ||
    a.issue_position[1] !== b.issue_position[1] ||
    a.issue_position[2] !== b.issue_position[2] ||
    a.message !== b.message ||
    !!a.archived !== !!b.archived ||
    a.comments.length !== b.comments.length ||
    a.timestamp !== b.timestamp
  ) {
    return false;
  }
  for (let i = 0; i < a.comments.length; ++i) {
    if (!areCommentsEqual(a.comments[i], b.comments[i])) {
      return false;
    }
  }
  return true;
}

export interface IWriteNotePopover {
  point: Vector3;
  pos: ScreenPosition;
}

export interface IPreviewNotePopover {
  note: Note;
  pos: ScreenPosition;
}

export interface INotesState {
  /**
   * Whether the notes are currently being fetched from the backend or not.
   */
  isFetching: boolean;

  /**
   * Notes are discussions between users about a particular issue (think about
   * threads in a forum). Such discussions can be geometry-related,
   * materials-related, etc. A single note contains all messages exchanged
   * between all users that take part in the discussion.
   */
  notes: Note[];

  /**
   * Array of note basic information (one for each note in `notes`) used as object to index.
   * This will be used to retrieve the note with mouse picking.
   */
  indexedNotes: IIndexedNote[];

  /**
   * RTree point for fast query.
   */
  noteGeoIndex: PunctualObjectRStarTree<Vector3, IIndexedNote>;

  /**
   * When defined, a popover is displayed in the 3d scene. This popover allows
   * the user to create a new note or edit an old one.
   */
  writeNotePopover?: IWriteNotePopover;

  /**
   * When defined, a popover is displayed in the 3d scene. This popover shows
   * the user a preview of a conversation about a note.
   */
  previewNotePopover?: IPreviewNotePopover;

  // The id of the selected note (if any)
  selectedNoteId?: string;

  // The id of the timer used to poll the /notes API endpoint.
  intervalTimer?: number;
}

export interface IApplicationState extends
  IMaterialsState,
  IAlmostAllApplicationState {
  /**
   * Style annotations about geometry or material issues of pieces, lines, etc.
   */
  readonly notes: INotesState;

  /**
   * Stack of interactors. Each interactor object implements the Interactor
   * interface.
   */
  readonly interactors: InteractorStack;
}

export interface IIssue {
  id: string;
  name: string;
}
