import { IProjectManager, isSerializable, Mark, PID, Project } from "cadius-db";

export class ProjectManager extends IProjectManager {
  public readonly projectID: string;

  private _cachedGeometries: Map<PID, picasso.Geometry>;

  private readonly _geoCacheEnabled: boolean;

  public retrieveMeshSolid(pid: PID, mark: Mark): picasso.MeshSolid | undefined {
    if (!this._geoCacheEnabled) {
      return undefined;
    }

    const serialized = this._cachedGeometries.get(pid);
    return serialized && serialized.geometryType === "MeshSolid" && serialized.mark === mark ? serialized : undefined;
  }

  public retrieveMeshPipes(pid: PID, mark: Mark): picasso.MeshPipes | undefined {
    if (!this._geoCacheEnabled) {
      return undefined;
    }

    const serialized = this._cachedGeometries.get(pid);
    return serialized && serialized.geometryType === "MeshPipes" && serialized.mark === mark ? serialized : undefined;
  }

  constructor(projectID: string) {
    super();
    this._cachedGeometries = new Map();
    this._geoCacheEnabled = !!window.localStorage.getItem("CADIUS_ENABLE_GEOMETRY_CACHE");
    this.projectID = projectID;
  }

  public isGeometricCacheEnabled(): boolean {
    return this._geoCacheEnabled;
  }

  public cacheGeometries(project: Project): void {
    if (!this._geoCacheEnabled) {
      return;
    }

    const cache = window.localStorage.getItem(this.projectID);
    if (cache) {

      const parsedCache = JSON.parse(cache);
      for (const k of Object.keys(parsedCache)) {
        const pid = Number.parseInt(k);
        const geo = parsedCache[k] as picasso.Geometry;
        this._cachedGeometries.set(pid, geo);
      }
    } else {
      const serialized = {} as any;
      for (const e of project) {
        if (isSerializable(e)) {
          try {
            const geo = e.serialize();
            serialized[e.pid.toString()] = geo;
            this._cachedGeometries.set(e.pid, geo);
          } catch (e) {
            // for the non serializable entities
          }
        }
      }
      window.localStorage.setItem(this.projectID, JSON.stringify(serialized));
    }
  }
}
