import { Render3DContext } from "cadius-cadlib";
import { Interactor, InteractorStack, IView } from "cadius-components";
import { log } from "cadius-stdlib";

import * as act from "../actions";
import { Cad3DAppControl } from "../controls/controls";
import { CameraControllerInteractor, PickNoteInteractor, SelectorInteractor } from "../interactors";
import { IAction, IApplicationState, UiMode } from "../interfaces";

export const reducer = (state: IApplicationState, action: IAction): IApplicationState => {
  switch (action.type) {
    case act.HANDLE_CAD_EVENT:
      Cad3DAppControl.beginDispatchCycle(state);
      state.interactors.dispatchEvent(action.payload.evt);

      if (Cad3DAppControl.refreshNeeded) {
        return {
          ...Cad3DAppControl.state,
          view: getUpdatedView(Cad3DAppControl.state),
        };
      }
      return Cad3DAppControl.state;

    case act.INVALID_DOM_EVENT:
      const { reason } = action.payload;
      log("INVALID DOM EVENT", reason);
      return state;

    case act.POP_FROM_INTERACTOR_STACK: {
      const kind = action.payload.kind;
      const p = state.interactors.pop(kind);
      p.then(() => {
        log(`interactor ${kind} POPPED from the stack`);
      });

      return state;
    }

    case act.PUSH_TO_INTERACTOR_STACK: {
      const interactor: Interactor = action.payload.interactor;
      const p = state.interactors.push(interactor);
      p.then(() => {
        log(`interactor ${interactor.kind} PUSHED to the stack`);
      });

      return state;
    }

    case act.CHANGE_UI_MODE: {
      const uiMode = action.payload.uiMode as UiMode;
      return {
        ...state,
        interactors: interactorStackMap[uiMode],
        uiMode,
      };
    }

    default:
      return state;
  }
};

function getUpdatedView(state: IApplicationState): IView {
  const ctx: Render3DContext = {
    debug: state.debug,
    envMaps: null,
    filters: state.filters,
    refresh: state.refresh,
  };

  return {
    ...state.view,
    cadView: state.view.cadView.touch(),
    scene: state.view.scene.touch(ctx),
  };
}

const changeMaterialInteractorStack = () => {
  const s = new InteractorStack();
  s.push(new SelectorInteractor());
  s.push(new CameraControllerInteractor());
  return s;
};

const annotateInteractorStack = () => {
  const s = new InteractorStack();
  s.push(new PickNoteInteractor());
  s.push(new CameraControllerInteractor());
  return s;
};

/**
 * The map with the initial state of the interactor stack for every UiMode.
 */
export const interactorStackMap = {
  [UiMode.CHANGE_MATERIAL]: changeMaterialInteractorStack(),
  [UiMode.ANNOTATE]: annotateInteractorStack(),
};

export const initialState = () => {
  const s = new InteractorStack();
  s.push(new SelectorInteractor());
  s.push(new CameraControllerInteractor());
  return s;
};
