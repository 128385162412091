import { Comment, Note } from "cadius-backend";
import { Expander } from "cadius-components";
import React, { useEffect, useState } from "react";

import { NoteConnected } from "../containers/NoteConnected";
import { CategoryColor } from "../interfaces";

interface IProps {
    /**
     * Callback invoked when the user adds a new comment to a note.
     */
    addCommentToNote: (
        projectId: string,
        noteId: string,
        comment: string
    ) => void;

    /**
     * Callback invoked when the user archives a note.
     */
    archiveNote: (projectId: string, noteId: string) => void;

    /**
     * Callback invoked when the user submits an edit to a comment in a note.
     */
    editCommentInNote: (
        projectId: string,
        noteId: string,
        commentId: string,
        editedComment: string
    ) => void;

    /**
     * The list of notes.
     */
    notes: Note[];

    /**
     * Picasso style project ID.
     */
    projectId?: string;

    /**
     * Callback invoked when the user deletes a comment in a note.
     */
    removeCommentFromNote: (
        projectId: string,
        noteId: string,
        commentId: string
    ) => void;

    /**
     * Callback invoked when the user deletes a note.
     */
    removeNote: (projectId: string, id: string) => void;

    /**
     * Callback invoked as soon as this component mounts (e.g. start
     * polling the /notes API and keep polling every `ms` milliseconds).
     */
    startAndKeepPollingNotes: (ms: number, projectId?: string) => void;

    /**
     * Callback invoked as soon as this component mounts (e.g. stop polling the
     * /notes API.
     */
    stopPollingNotes: (intervalTimer?: number) => void;

    /**
     * Callback invoked when the user unarchives a note.
     */
    unarchiveNote: (projectId: string, noteId: string) => void;
}

const getUserTypeFromUserName = (username: string) => {
    return username.indexOf("s") === 0 ? "Stylist" : "Pattern maker";
};

export const Notes: React.FC<IProps> = ({
    addCommentToNote,
    archiveNote,
    editCommentInNote,
    notes,
    projectId,
    removeCommentFromNote,
    removeNote,
    startAndKeepPollingNotes,
    stopPollingNotes,
    unarchiveNote,
}) => {
    useEffect(() => {
        startAndKeepPollingNotes(5000, projectId);
        return () => {
            stopPollingNotes();
        };
    }, [projectId]);

    const [areActiveNotesShown, setAreActiveNotesShown] = useState(true);
    const [areArchivedNotesShown, setAreArchivedNotesShown] = useState(false);

    type Cb = () => void;

    const renderNote = (n: Note) => {
        const username = getUserTypeFromUserName(n.username);

        // TODO: extract the avatar URL from each note
        const author = {
            avatar: "some-image-url",
            name: getUserTypeFromUserName(n.username),
            username,
        };

        let onArchive: Cb | undefined;
        if (n.archived) {
            onArchive = undefined;
        } else {
            onArchive = () => {
                if (projectId && n.id) {
                    archiveNote(projectId, n.id);
                }
            };
        }

        const onDelete = () => {
            if (projectId && n.id) {
                removeNote(projectId, n.id);
            }
        };

        const onDeleteComment = (commentId: string) => {
            if (projectId && n.id) {
                removeCommentFromNote(projectId, n.id, commentId);
            }
        };

        const onEditComment = (commentId: string, editedComment: string) => {
            if (projectId && n.id) {
                editCommentInNote(projectId, n.id, commentId, editedComment);
            }
        };

        let onUnarchive: Cb | undefined;
        if (n.archived) {
            onUnarchive = () => {
                if (projectId && n.id) {
                    unarchiveNote(projectId, n.id);
                }
            };
        } else {
            onUnarchive = undefined;
        }

        const onNewComment = (comment: string) => {
            if (projectId && n.id) {
                addCommentToNote(projectId, n.id, comment);
            }
        };

        // TODO: remove mock data when it will be available from the API.
        const comments = n.comments.map((c: Comment) => ({
            ...c,
            message: c.message || "empty-message",
            // timestamp: "",
            // timestamp: c.timestamp || "yesterday",
            timestamp: new Date(c.timestamp!).toLocaleDateString("it"),
            username: getUserTypeFromUserName(c.username || "u01"),
        }));

        // TODO: empty string because the timestamp does not look good in the ExpanderLabel
        // Probably we should format the timestamp in the ExpanderLabel or in one of
        // its parent components.
        const timestamp = "";

        return (
            <NoteConnected
                archived={n.archived}
                author={author}
                // camera={n.camera}
                categoryColor={
                    n.issue === "geometry"
                        ? CategoryColor.geometry
                        : CategoryColor.material
                }
                comments={comments}
                content={n.message}
                id={n.id}
                key={n.id || "tmp-key"}
                onArchive={onArchive}
                onDelete={onDelete}
                onDeleteComment={onDeleteComment}
                onEditComment={onEditComment}
                onNewComment={onNewComment}
                onUnarchive={onUnarchive}
                timestamp={timestamp}
            />
        );
    };

    const onToggleArchivedNotes = () => {
        setAreArchivedNotesShown(!areArchivedNotesShown);
    };

    const onToggleActiveNotes = () => {
        setAreActiveNotesShown(!areActiveNotesShown);
    };

    return (
        <div className="stack-dense">
            <Expander
                isExpanded={areArchivedNotesShown}
                label="archived notes"
                onToggle={onToggleArchivedNotes}
            >
                <div className="stack-dense">
                    {notes.filter((n: Note) => n.archived).map(renderNote)}
                </div>
            </Expander>
            <Expander
                isExpanded={areActiveNotesShown}
                label="active notes"
                onToggle={onToggleActiveNotes}
            >
                <div className="stack-dense">
                    {notes.filter((n: Note) => !n.archived).map(renderNote)}
                </div>
            </Expander>
        </div>
    );
};
